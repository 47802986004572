import { useLocalStorage } from "@mantine/hooks";
import { NotificationData, notifications } from "@mantine/notifications";
import { useEffect } from "react";

type DefaultValue = {
  version: number | null;
};

const keyPrefix = "proximal-tips";

const useTips = ({
  key,
  currentVersion,
  notification,
}: {
  key: string;
  currentVersion: number;
  notification: NotificationData;
}) => {
  const [hasVisited, setHasVisited] = useLocalStorage<DefaultValue>({
    key: `${keyPrefix}-${key}`,
    defaultValue: { version: null },
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    if (hasVisited.version !== currentVersion) {
      notifications.show({ ...notification });
      setHasVisited({ version: currentVersion });
    }
  }, [hasVisited, setHasVisited, currentVersion, notification]);
};

export const useTipsPCSGIS = () => {
  const id = "pcs-gis";

  useTips({
    key: id,
    currentVersion: 2,
    notification: {
      id: id,
      title: "PCS GIS",
      message:
        "You can view live power output or aggregated energy generation. By default, the map shows live power. Select a date range to view aggregated energy generation. Clear the date range to return to live power.",
      autoClose: false,
    },
  });
};

export const useTipsPersonalPortfolio = () => {
  const id = "personal-portfolio";

  useTips({
    key: id,
    currentVersion: 1,
    notification: {
      id: id,
      message:
        "You can customize visible projects by creating your own Personal Portfolio. Click on Settings in the navigation bar to customize.",
      autoClose: false,
    },
  });
};

export const clearTips = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(keyPrefix)) {
      localStorage.removeItem(key);
    }
  });
};

import CustomCard from "@/components/CustomCard";
import { useValidateDateRange } from "@/components/datepicker/utils";
import { AdvancedDatePicker } from "@/components/GIS";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetProject, useGetProjectCycleCountKPIData } from "@/hooks/api";
import { Stack, Title } from "@mantine/core";
import { useParams } from "react-router-dom";

const Page = () => {
  const { projectId } = useParams();

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const { start, end } = useValidateDateRange({});

  let startQuery: string | undefined = undefined;
  let endQuery: string | undefined = undefined;

  if (project.data) {
    if (start) {
      startQuery = start.tz(project.data.time_zone, true).toISOString();
    }
    if (end) {
      endQuery = end.tz(project.data.time_zone, true).toISOString();
    }
  }

  const data = useGetProjectCycleCountKPIData({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      start: startQuery,
      end: endQuery,
    },
    queryOptions: {
      enabled: !!project.data && !!start && !!end,
    },
  });

  return (
    <Stack p="md" h="100%">
      <Title order={1}>Project Cycle Count</Title>
      <AdvancedDatePicker includeClearButton={false} defaultRange="past-week" />
      <CustomCard title="Cycles" style={{ flex: 1 }}>
        <PlotlyPlot
          data={
            data.data && [
              {
                x: data.data.index,
                y: data.data.data.map((d) => d[0]),
                type: "scatter",
                mode: "lines",
              },
            ]
          }
          layout={{
            yaxis: {
              rangemode: "tozero",
            },
          }}
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
    </Stack>
  );
};

export default Page;

import { PageError } from "@/components/Error";
import { PageLoader } from "@/components/Loading";
import {
  useGetPortfolioCardData,
  useGetProject,
  useGetProjects,
} from "@/hooks/api";
import { Group, Paper, SimpleGrid, Skeleton } from "@mantine/core";
import { Link } from "react-router-dom";
import DataStatus from "../layout/header/DataStatus";

function PortfolioHome() {
  const projects = useGetProjects({});

  if (projects.isLoading) {
    return <PageLoader />;
  }

  if (projects.isError) {
    return <PageError error={projects.error} />;
  }

  return (
    <SimpleGrid p="md" cols={{ base: 1, sm: 2, lg: 3 }}>
      {projects.data?.map((project) => (
        <PortfolioProjectCard key={project.project_id} project={project} />
      ))}
    </SimpleGrid>
  );
}

function PortfolioProjectCard({
  project,
}: {
  project: ReturnType<typeof useGetProject>["data"];
}) {
  const data = useGetPortfolioCardData({
    pathParams: { projectId: project ? project.project_id : "-1" },
    queryOptions: { enabled: !!project },
  });

  return (
    <Skeleton visible={data.isLoading}>
      <Paper p="md" shadow="md" withBorder>
        {project && (
          <Group>
            <Link
              to={`/projects/${project.project_id}`}
              style={{ color: "inherit" }}
            >
              {project.name_long}
            </Link>
            {!data.isLoading && <DataStatus projectId={project.project_id} />}
            {`${data.data?.count || "Unknown"}/${data.data?.total}`}
          </Group>
        )}
      </Paper>
    </Skeleton>
  );
}

export default PortfolioHome;

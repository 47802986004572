import { Link, useParams } from "react-router-dom";

import { PageError } from "@/components/Error";
import { PageLoader } from "@/components/Loading";
import { useGetMaximo, useGetMaximoAssets } from "@/hooks/api";
import { Button, Group, ScrollArea, Select, Stack, Text } from "@mantine/core";
import { IconArrowBackUp } from "@tabler/icons-react";
import { useState } from "react";
import MaximoCard from "./MaximoCard";

const MaximoByAsset = () => {
  const { projectId } = useParams();
  const [value, setValue] = useState<string | null>("");

  const assets = useGetMaximoAssets({
    pathParams: { projectId: projectId || "-1" },
  });

  const workOrders = useGetMaximo({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { assetnum: value || "" },
    queryOptions: { enabled: !!value },
  });

  if (assets.isLoading || workOrders.isLoading) return <PageLoader />;
  if (assets.error) return <PageError error={assets.error} />;

  return (
    <Stack p="md" h="100%">
      <Group align="end">
        <Select
          label="Maximo Assets"
          placeholder="Select an asset"
          data={assets.data?.map((asset) => ({
            value: asset.assetnum,
            label: `${asset.description} (Asset Number: ${asset.assetnum})`,
          }))}
          value={value}
          onChange={(value) => setValue(value)}
          searchable
          flex={1}
        />
        <Link to={`/projects/${projectId}/maximo`}>
          <Button variant="light" rightSection={<IconArrowBackUp size={14} />}>
            All Work Orders
          </Button>
        </Link>
      </Group>

      <ScrollArea flex={1}>
        <Stack gap="md">
          {/* Check if the length if workOrders.data is greater than zero */}
          {workOrders.data?.length || -1 > 0 ? (
            workOrders.data?.map((item) => (
              <MaximoCard key={item.id} data={item} />
            ))
          ) : (
            <Text>No Work Orders</Text>
          )}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};

export default MaximoByAsset;

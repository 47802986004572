// ClearskyFilter.tsx
import CustomCard from "@/components/CustomCard";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import { useGetClearskyPOA } from "@/hooks/api";
import {
  Box,
  Button,
  Group,
  HoverCard,
  Slider,
  Stack,
  Text,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconInfoCircle } from "@tabler/icons-react";
import dayjs from "dayjs";
import timezonePlugin from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Layout } from "plotly.js";
import React, { useMemo } from "react";

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

interface ClearskyFilterProps {
  date: dayjs.Dayjs;
  setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
  minPOA: number;
  setMinPOA: React.Dispatch<React.SetStateAction<number>>;
  maxPOA1stDerivative: number;
  setMaxPOA1stDerivative: React.Dispatch<React.SetStateAction<number>>;
  maxPOA1stDerivativeStd: number;
  setMaxPOA1stDerivativeStd: React.Dispatch<React.SetStateAction<number>>;
  handleGenerateData: () => void;
  timezone: string;
  projectId: string;
}

const ClearskyFilter: React.FC<ClearskyFilterProps> = ({
  date,
  setDate,
  minPOA,
  setMinPOA,
  maxPOA1stDerivative,
  setMaxPOA1stDerivative,
  maxPOA1stDerivativeStd,
  setMaxPOA1stDerivativeStd,
  handleGenerateData,
  timezone,
  projectId,
}) => {
  const { data: poaData, isLoading: poaDataLoading } = useGetClearskyPOA({
    pathParams: { projectId: projectId || "" },
    queryParams: {
      start: date.subtract(1, "day").toISOString(),
      end: date.toISOString(),
    },
  });

  const {
    data: plotData,
    layout,
    validPoints,
  } = useMemo(() => {
    return processPoaData(
      poaData || [],
      maxPOA1stDerivative,
      minPOA,
      maxPOA1stDerivativeStd,
      timezone || ""
    );
  }, [poaData, maxPOA1stDerivative, minPOA, maxPOA1stDerivativeStd, timezone]);

  return (
    <Group grow align="stretch">
      <CustomCard title="Clearsky Filter Options">
        <Stack>
          <Group>
            <Text size="sm" flex={1}>
              Date
            </Text>
            <DateInput
              flex={1}
              value={date.toDate()}
              onChange={(date) => date && setDate(dayjs(date).tz(timezone))}
              maxDate={dayjs().subtract(1, "day").tz(timezone).toDate()}
            />
          </Group>
          <Group>
            <Text size="sm" flex={1}>
              Min. POA
            </Text>
            <Box flex={1}>
              <Slider
                value={minPOA}
                onChange={setMinPOA}
                min={0}
                max={1000}
                step={50}
              />
            </Box>
          </Group>
          <Group>
            <Group flex={1} gap="xs">
              <HoverCard position="right">
                <HoverCard.Target>
                  <IconInfoCircle size="1.25rem" />
                </HoverCard.Target>
                <HoverCard.Dropdown w="25%">
                  Maximum allowed POA 1st derivative. Represents how quickly the
                  POA is changing. Lower values will remove periods with sudden
                  changes in POA. Recommended value of 1.
                </HoverCard.Dropdown>
              </HoverCard>
              <Text size="sm">Max. POA 1st Derivative</Text>
            </Group>
            <Box flex={1}>
              <Slider
                value={maxPOA1stDerivative}
                onChange={setMaxPOA1stDerivative}
                min={0}
                max={10}
                step={0.1}
              />
            </Box>
          </Group>
          <Group>
            <Group flex={1} gap="xs">
              <HoverCard position="right">
                <HoverCard.Target>
                  <IconInfoCircle size="1.25rem" />
                </HoverCard.Target>
                <HoverCard.Dropdown w="25%">
                  Maximum allowed standard deviation of the first derivative of
                  the POA. This value is a measure of the coherence of
                  meteorological stations in different locations onsite.
                  Recommended value of 1.
                </HoverCard.Dropdown>
              </HoverCard>
              <Text size="sm">Max. POA 1st Derivative Std Dev</Text>
            </Group>
            <Box flex={1}>
              <Slider
                value={maxPOA1stDerivativeStd}
                onChange={setMaxPOA1stDerivativeStd}
                min={0}
                max={10}
                step={0.1}
              />
            </Box>
          </Group>
          <Button onClick={handleGenerateData} disabled={validPoints === 0}>
            {validPoints
              ? `Generate Data (${validPoints} points)`
              : "No valid points"}
          </Button>
        </Stack>
      </CustomCard>

      <CustomCard title="Clearsky Filter Data">
        <div style={{ height: "100%", width: "100%" }}>
          <PlotlyPlot
            data={plotData}
            layout={layout}
            isLoading={poaDataLoading}
          />
        </div>
      </CustomCard>
    </Group>
  );
};

// Helper function
const processPoaData = (
  poaData: any[],
  maxPOA1stDerivative: number,
  minPOA: number,
  maxPOA1stDerivativeStd: number,
  timezone: string
): { data: any[]; layout: Partial<Layout>; validPoints: number } => {
  let validPoints = 0;
  if (!poaData) return { data: [], layout: {}, validPoints };

  const poa1dTrace = poaData.find((trace) => trace.name === "POA 1D");
  // const poa2dTrace = poaData.find((trace) => trace.name === "POA 2D");
  const poa1dStdTrace = poaData.find(
    (trace) => trace.name === "POA 1D Std Dev"
  );
  if (!poa1dTrace || !poa1dStdTrace)
    return { data: poaData, layout: {}, validPoints };

  const otherTraces = poaData.filter(
    (trace) => !["POA 1D", "POA 1D Std Dev"].includes(trace.name)
  );

  const shapes: any[] = [];
  let currentShape: any = null;

  poa1dTrace.x.forEach((x: string | number, i: number) => {
    const y1d = poa1dTrace.y[i];
    // const y2d = poa2dTrace.y[i];
    const y1dStd = poa1dStdTrace.y[i];

    const meanOther =
      otherTraces.reduce((sum, trace) => sum + (trace.y[i] || 0), 0) /
      otherTraces.length;

    const isValidPoint =
      y1d != null &&
      // y2d != null &&
      y1dStd != null &&
      Math.abs(y1d) < maxPOA1stDerivative &&
      meanOther > minPOA &&
      y1dStd < maxPOA1stDerivativeStd;

    if (isValidPoint) {
      validPoints++;
      if (!currentShape) {
        currentShape = {
          type: "rect",
          xref: "x",
          yref: "paper",
          x0: x,
          y0: 0,
          x1: x,
          y1: 1,
          fillcolor: "rgba(0, 255, 0, 0.2)",
          line: { width: 0 },
        };
      } else {
        currentShape.x1 = x;
      }
    } else if (currentShape) {
      shapes.push(currentShape);
      currentShape = null;
    }
  });

  if (currentShape) {
    shapes.push(currentShape);
  }

  // Adjust single-point shapes
  shapes.forEach((shape) => {
    if (shape.x0 === shape.x1) {
      const hourOffset = dayjs(shape.x0).tz(timezone).utcOffset() / 60;
      const x0 = dayjs(shape.x0)
        .tz(timezone)
        .subtract(2, "minute")
        .add(hourOffset, "hour")
        .toISOString();
      const x1 = dayjs(shape.x1)
        .tz(timezone)
        .add(2, "minute")
        .add(hourOffset, "hour")
        .toISOString();
      shape.x0 = x0;
      shape.x1 = x1;
    }
  });

  const layout: Partial<Layout> = {
    shapes: shapes,
    showlegend: true,
    yaxis: {
      title: "POA",
    },
    yaxis2: {
      title: "Filters",
      showgrid: false,
      zeroline: false,
      side: "right" as const,
      overlaying: "y",
    },
  };

  return { data: poaData, layout, validPoints };
};

export default ClearskyFilter;

import { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  rem,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import classes from "./NavbarLinksGroup.module.css";
import { Link } from "react-router-dom";

export interface DropdownLinkProps {
  label: string;
  to: string;
  underDevelopment: boolean;
}

export interface LinksGroupProps {
  icon: React.ElementType;
  label: string;
  initiallyOpened?: boolean;
  to?: string;
  links?: DropdownLinkProps[];
  underDevelopment?: boolean;
}

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  to,
  links,
}: LinksGroupProps) {
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const items = (hasLinks ? links : []).map((link) => (
    <Link to={link.to} style={{ textDecoration: "none" }} key={link.label}>
      <Text className={classes.link}>{link.label}</Text>
    </Link>
  ));

  const unstyledButton = (
    <UnstyledButton
      onClick={() => setOpened((o) => !o)}
      className={classes.control}
    >
      <Group justify="space-between" gap={0} pl="md" pr="md">
        <Box style={{ display: "flex", alignItems: "center" }} p={5} pl={0}>
          <ThemeIcon variant="light" size={30}>
            <Icon style={{ width: rem(18), height: rem(18) }} />
          </ThemeIcon>
          <Box ml="md">{label}</Box>
        </Box>
        {hasLinks && (
          <IconChevronRight
            className={classes.chevron}
            stroke={1.5}
            style={{
              width: rem(16),
              height: rem(16),
              transform: opened ? "rotate(-90deg)" : "none",
            }}
          />
        )}
      </Group>
    </UnstyledButton>
  );

  return (
    <>
      {to ? (
        <Link to={to} style={{ textDecoration: "none" }}>
          {unstyledButton}
        </Link>
      ) : (
        unstyledButton
      )}
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

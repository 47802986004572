import { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of the context
interface ProjectDropdownContextType {
  isProjectDropdownEnabled: boolean;
  enableProjectDropdown: () => void;
  disableProjectDropdown: () => void;
}

// Create the context with an initial undefined value
const ProjectDropdownContext = createContext<
  ProjectDropdownContextType | undefined
>(undefined);

// Hook for easy use of the context
export function useProjectDropdown() {
  const context = useContext(ProjectDropdownContext);
  if (context === undefined) {
    throw new Error(
      "useProjectDropdown must be used within a ProjectDropdownProvider"
    );
  }
  return context;
}

// Provider component with type for props
interface ProjectDropdownProviderProps {
  children: ReactNode;
}

export function ProjectDropdownProvider({
  children,
}: ProjectDropdownProviderProps) {
  const [isProjectDropdownEnabled, setProjectDropdownEnabled] = useState(true);

  const enableProjectDropdown = () => setProjectDropdownEnabled(true);
  const disableProjectDropdown = () => setProjectDropdownEnabled(false);

  // The value provided to the context consumers
  const value = {
    isProjectDropdownEnabled,
    enableProjectDropdown,
    disableProjectDropdown,
  };

  return (
    <ProjectDropdownContext.Provider value={value}>
      {children}
    </ProjectDropdownContext.Provider>
  );
}

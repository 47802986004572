import { useProjectDropdown } from "@/providers/ProjectDropdownProvider";
import { useEffect } from "react";

// Custom hook for enabling/disabling the dropdown
export function useProjectDropdownToggle() {
  const { disableProjectDropdown, enableProjectDropdown } =
    useProjectDropdown();

  useEffect(() => {
    // Disable dropdown when the component using this hook mounts
    disableProjectDropdown();

    // Cleanup function to enable dropdown when the component unmounts
    return () => enableProjectDropdown();
  }, [disableProjectDropdown, enableProjectDropdown]);
}

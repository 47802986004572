import * as types from "@/hooks/types";
import { useAuth } from "@clerk/clerk-react";
import { TreeNodeData } from "@mantine/core";
import { readLocalStorageValue } from "@mantine/hooks";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { FeatureCollection } from "geojson";
import qs from "qs";

const baseURL = import.meta.env.PROD
  ? "https://api.proximal.energy"
  : "http://127.0.0.1:8000";

export const useCustomQuery = <T>({
  axiosConfig,
  queryName,
  pathParams = {},
  queryParams = {},
  queryOptions = {},
}: {
  axiosConfig: AxiosRequestConfig;
  queryName: string;
  pathParams?: object;
  queryParams?: object;
  queryOptions?: object;
}) => {
  const { getToken } = useAuth();

  const queryKey: unknown[] = [queryName];

  // If pathParams is not empty, add it to queryKey
  if (Object.keys(pathParams).length !== 0) {
    queryKey.push(pathParams);
  }

  // If queryParams is not empty, add it to queryKey
  if (Object.keys(queryParams).length !== 0) {
    queryKey.push(queryParams);
  }

  const queryFn = async (): Promise<T> => {
    const token = await getToken({ template: "default" });
    const response = await axios({
      ...axiosConfig,
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return response.data;
  };

  return useQuery({
    queryKey: queryKey,
    queryFn: () => queryFn(),
    ...queryOptions,
  });
};

export const useGetApiKey = ({
  queryOptions = {},
}: {
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: "/v1/admin/api-key",
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.ApiKey>({
    axiosConfig,
    queryName: "getApiKey",
    pathParams: {},
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useCreateApiKeyMutation = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "post",
        url: `${baseURL}/v1/admin/api-key`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getApiKey"] });
    },
  });
};

export const useDeleteApiKeyMutation = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "delete",
        url: `${baseURL}/v1/admin/api-key`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getApiKey"] });
    },
  });
};

export const useCreateFeedbackMutation = () => {
  const { getToken } = useAuth();
  return useMutation({
    mutationFn: async (feedback: FormData) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "post",
        url: `${baseURL}/v1/feedback`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: feedback,
      });
    },
  });
};

export const useGetAlertPreferences = ({
  queryOptions = {},
}: {
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: "/v1/admin/alert-preferences/",
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<boolean>({
    axiosConfig,
    queryName: "getAlertPreferences",
    pathParams: {},
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useUpdateAlertPreferencesMutation = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "put",
        url: `${baseURL}/v1/admin/alert-preferences/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAlertPreferences"] });
    },
  });
};

export const useGetReportPreferences = ({
  queryOptions = {},
}: {
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: "/v1/admin/report-preferences/",
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<boolean>({
    axiosConfig,
    queryName: "getReportPreferences",
    pathParams: {},
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useUpdateReportPreferencesMutation = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "put",
        url: `${baseURL}/v1/admin/report-preferences/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getReportPreferences"] });
    },
  });
};

export const useGetSubscriptions = ({
  queryOptions = {},
}: {
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: "/v1/admin/subscriptions/",
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.UserSubscription[]>({
    axiosConfig,
    queryName: "getSubscriptions",
    queryOptions: queryOptions,
  });
};

export const useUpdateNotificationSubscription = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      project_id,
      subscribe,
    }: {
      project_id: string;
      subscribe: boolean;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "put",
        url: `${baseURL}/v1/admin/subscriptions/notifications/${project_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          subscribe,
        },
      });
    },
    onMutate: async (newSubscription) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["getSubscriptions"] });

      // Snapshot the previous value
      const previousSubscriptions = queryClient.getQueryData<
        types.UserSubscription[]
      >(["getSubscriptions"]);

      // Optimistically update the new value
      queryClient.setQueryData(
        ["getSubscriptions"],
        (oldSubscriptions: types.UserSubscription[]) => {
          return oldSubscriptions?.map((subscription) => {
            if (
              subscription.operational_project_id === newSubscription.project_id
            ) {
              return {
                ...subscription,
                notifications: newSubscription.subscribe,
              };
            }
            return subscription;
          });
        }
      );

      // Return a context object with the snapshotted value
      return { previousSubscriptions };
    },
    onError: (_err, _newSubscription, context) => {
      queryClient.setQueryData(
        ["getSubscriptions"],
        context?.previousSubscriptions
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["getSubscriptions"] });
    },
  });
};

export const useUpdateReportSubscription = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      project_id,
      subscribe,
    }: {
      project_id: string;
      subscribe: boolean;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "put",
        url: `${baseURL}/v1/admin/subscriptions/reports/${project_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          subscribe,
        },
      });
    },
    onMutate: async (newSubscription) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["getSubscriptions"] });

      // Snapshot the previous value
      const previousSubscriptions = queryClient.getQueryData<
        types.UserSubscription[]
      >(["getSubscriptions"]);

      // Optimistically update the new value
      queryClient.setQueryData(
        ["getSubscriptions"],
        (oldSubscriptions: types.UserSubscription[]) => {
          return oldSubscriptions?.map((subscription) => {
            if (
              subscription.operational_project_id === newSubscription.project_id
            ) {
              return {
                ...subscription,
                reports: newSubscription.subscribe,
              };
            }
            return subscription;
          });
        }
      );

      // Return a context object with the snapshotted value
      return { previousSubscriptions };
    },
    onError: (_err, _newSubscription, context) => {
      queryClient.setQueryData(
        ["getSubscriptions"],
        context?.previousSubscriptions
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["getSubscriptions"] });
    },
  });
};

export const useGetProject = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Project>({
    axiosConfig,
    queryName: "getProject",
    pathParams,
    queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetProjects = ({
  queryParams = {},
  queryOptions = {},
  personalPortfolio = true,
}: {
  queryParams?: {
    [key: string]: string | number | boolean | Array<string | number | boolean>;
  };
  queryOptions?: Partial<UseQueryOptions>;
  personalPortfolio?: boolean;
}) => {
  let queryName: string;
  if (personalPortfolio) {
    queryName = "getProjectsPersonal";
  } else {
    queryName = "getProjects";
  }

  const excludedProjectIds = readLocalStorageValue<string[]>({
    key: "proximal-personal-portfolio-excluded-project-ids",
    defaultValue: [],
  });

  if (personalPortfolio) {
    queryParams["project_ids_excluded"] = excludedProjectIds;
  }

  const axiosConfig = {
    url: "/v1/operational/projects",
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = {
    ...defaultQueryOptions,
    ...queryOptions,
    enabled: queryOptions.enabled !== false && excludedProjectIds !== undefined,
  };

  return useCustomQuery<types.Project[]>({
    axiosConfig,
    queryName: queryName,
    pathParams: {},
    queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetProjectDataLastUpdated = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/data-last-updated`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.ProjectDataLastUpdated>({
    axiosConfig,
    queryName: "getProjectLatestData",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetDevice = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string; deviceId: string };
  queryParams?: { deep?: boolean };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/devices/${pathParams.deviceId}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Device>({
    axiosConfig,
    queryName: "getDevice",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetDevices = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/devices`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Device[]>({
    axiosConfig,
    queryName: "getDevices",
    pathParams,
    queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetDeviceTypes = ({
  queryParams = {},
  queryOptions = {},
}: {
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/device_types/`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Device[]>({
    axiosConfig,
    queryName: "getDeviceTypes",
    pathParams: {},
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetTags = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/tags`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Tag[]>({
    axiosConfig,
    queryName: "getTags",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetBrowsingTree = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/trees/${pathParams.projectId}/browsing-tree`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};
  queryOptions = { ...defaultQueryOptions, ...queryOptions };
  return useCustomQuery<TreeNodeData[]>({
    axiosConfig,
    queryName: "getBrowsingTree",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetKPIInstances = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: { deep?: boolean };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-instances/`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.KPIInstances[]>({
    axiosConfig,
    queryName: "getKPIInstances",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetKPISummary = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/kpi-summary-cards/`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 6, // 6 hours
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };
  return useCustomQuery<types.KPICardProps[]>({
    axiosConfig,
    queryName: "getKPISummary",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetKPIAlerts = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: { kpi_type_id?: number };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/kpi-alerts/`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.KPIAlertProps[]>({
    axiosConfig,
    queryName: "getKPIAlerts",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetBESSBlockCycleCountKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/bess-block-cycle-count`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getBESSBlockCycleCountKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetBESSBlockRestingSOCPercentKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/bess-block-resting-soc-percent`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getBESSBlockRestingSOCPercentKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetBESSBlockAverageSOCPercentKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/bess-block-average-soc-percent`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getBESSBlockAverageSOCPercentKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetPCSMechanicalAvailabilityKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/pcs-mechanical-availability`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getPCSMechanicalAvailabilityKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetPVDCCombinerFuseHealthKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/pv-dc-combiner-fuse-health`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getPVDCCombinerFuseHealthKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetProjectCycleCountKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/project-cycle-count`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getProjectCycleCountKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetProjectEnergyProductionKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/project-energy-production`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getProjectEnergyProductionKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetProjectPCSMechanicalAvailabilityData = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/project-pcs-mechanical-availability`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.KPITimeSeries>({
    axiosConfig,
    queryName: "getKPIData",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetProjectRestingSOCKPIData = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/project-resting-soc`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DatetimeDataFrame>({
    axiosConfig,
    queryName: "getProjectRestingSOCKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetTrackerEquipmentAnalysis = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/equipment-analysis/tracker`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.TrackerEquipmentAnalysisData>({
    axiosConfig,
    queryName: "getTrackerPositionDeviatingFromSetpointKPIData",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetDCAmperageReport = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams: {
    start: string;
    min_poa: number;
    max_poa_1d: number;
    max_poa_std: number;
    rolling_window: number;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig: AxiosRequestConfig = {
    url: `/v1/analytics/${pathParams.projectId}/dc-amperage-report`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DCAmperageData>({
    axiosConfig,
    queryName: "getDCAmperageReport",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetRowData = () => {
  const axiosConfig = {
    url: "/v1/row-data",
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  return useCustomQuery<types.RowData[]>({
    axiosConfig,
    queryName: "getRowData",
    pathParams: {},
    queryParams: {},
    queryOptions: defaultQueryOptions,
  });
};

export const useGetEvents = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    device_id?: string;
    open?: boolean;
    event_ids?: number[];
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/events`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Event[]>({
    axiosConfig,
    queryName: "getEvents",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetPaginatedEvents = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    page?: number;
    page_size?: number;
    open?: boolean;
    sort_column?: string;
    sort_direction?: string;
    device_type_ids?: number[];
    device_ids?: number[];
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/events/paginated-events`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.EventSummary[]>({
    axiosConfig,
    queryName: "getPaginatedEvents",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetEventsById = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string; eventId: number };
  queryParams?: {
    open?: boolean;
    deep?: boolean;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/events/get/${pathParams.eventId}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Event>({
    axiosConfig,
    queryName: "getEventsById",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetEventLosses = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    event_ids?: number[];
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/events/event-losses`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.EventLoss[]>({
    axiosConfig,
    queryName: "getEventLosses",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetFailureModes = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    failure_mode_ids?: number[];
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/failure-modes`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.FailureMode[]>({
    axiosConfig,
    queryName: "getFailureModes",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useUpdateFailureMode = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      project_id,
      event_id,
      failure_mode_id,
    }: {
      project_id: string;
      event_id: number;
      failure_mode_id: number;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "put",
        url: `${baseURL}/v1/operational/projects/${project_id}/events/${event_id}/failure-mode`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          failure_mode_id,
        },
      });
    },
    onMutate: async (newFailureMode) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["getFailureModes"] });

      // Snapshot the previous value
      const previousFailureModes = queryClient.getQueryData<
        types.FailureMode[]
      >(["getFailureModes"]);

      // Optimistically update the new value
      queryClient.setQueryData(
        ["getFailureModes"],
        (oldFailureModes: types.FailureMode[]) => {
          return oldFailureModes?.map((failureMode) => {
            if (
              failureMode.failure_mode_id === newFailureMode.failure_mode_id
            ) {
              return {
                ...failureMode,
                failure_mode_id: newFailureMode.failure_mode_id,
              };
            }
            return failureMode;
          });
        }
      );
      return { previousFailureModes };
    },
    onError: (_err, _newFailureMode, context) => {
      queryClient.setQueryData(
        ["getFailureModes"],
        context?.previousFailureModes
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["getFailureModes"] });
    },
  });
};

export const useGetRootCauses = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    root_cause_ids?: number[];
    device_type_ids?: number[];
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/root-causes`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.RootCause[]>({
    axiosConfig,
    queryName: "getRootCauses",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useUpdateRootCause = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      project_id,
      event_id,
      root_cause_id,
    }: {
      project_id: string;
      event_id: number;
      root_cause_id?: number;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "put",
        url: `${baseURL}/v1/operational/projects/${project_id}/events/${event_id}/root-cause`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          root_cause_id: root_cause_id ?? -1,
        },
      });
    },
    onMutate: async (newRootCause) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["getRootCauses"] });

      // Snapshot the previous value
      const previousRootCauses = queryClient.getQueryData<types.RootCause[]>([
        "getRootCauses",
      ]);

      // Optimistically update the new value
      queryClient.setQueryData(
        ["getRootCauses"],
        (oldRootCauses: types.RootCause[]) => {
          return oldRootCauses?.map((rootCause) => {
            if (rootCause.root_cause_id === newRootCause.root_cause_id) {
              return {
                ...rootCause,
                root_cause_id: newRootCause.root_cause_id,
              };
            }
            return rootCause;
          });
        }
      );
      return { previousRootCauses };
    },
    onError: (_err, _newRootCause, context) => {
      queryClient.setQueryData(["getRootCauses"], context?.previousRootCauses);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["getRootCauses"] });
    },
  });
};

export const useGetMaximo = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: { assetnum?: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/events/maximo`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.MaximoWorkOrder[]>({
    axiosConfig,
    queryName: "getMaximo",
    pathParams: pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetMaximoByAsset = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string; assetnum: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${
      pathParams.projectId
    }/events/maximo-by-asset/${encodeURIComponent(pathParams.assetnum)}`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.MaximoWorkOrder[]>({
    axiosConfig,
    queryName: "getMaximoByAsset",
    pathParams: pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetMaximoAssets = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/events/maximo/assets`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.MaximoAsset[]>({
    axiosConfig,
    queryName: "getMaximoAssets",
    pathParams: pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetPortfolioCardData = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/portfolio-card`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<{
    count: number | null;
    total: number;
  }>({
    axiosConfig,
    queryName: "getPortfolioCardData",
    pathParams: pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetWeather = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/project-weather`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: 15 * 60 * 1000, // 15 minutes
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.WeatherResponse>({
    axiosConfig,
    queryName: "getWeather",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetForecast = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/project-weather-forecast`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: 15 * 60 * 1000, // 15 minutes
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.ForecastResponse>({
    axiosConfig,
    queryName: "getForecast",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetTimeSeries = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    tag_ids?: number[];
    device_ids?: number[];
    parent_device_id?: string;
    sensor_type_name_shorts?: string[];
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/time-series`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DataTimeSeries[]>({
    axiosConfig,
    queryName: "getTimeSeries",
    pathParams,
    queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetMeterPowerAndExpectedPower = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/meter-power-and-expected-power-v2`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.MeterPowerAndExpected>({
    axiosConfig,
    queryName: "getMeterPowerAndExpectedPower",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetHeatmap = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string; sensorTypeName: string };
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/heatmap/${pathParams.sensorTypeName}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DataHeatmap>({
    axiosConfig,
    queryName: "getHeatmap",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetGeo = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/geo`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<FeatureCollection>({
    axiosConfig,
    queryName: "getGeo",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetGISPCS = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/gis/pcs`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.GISPCS>({
    axiosConfig,
    queryName: "getPCSPerformance",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetGISCombiner = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/gis/combiner`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    staleTime: 60 * 1000,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<FeatureCollection>({
    axiosConfig,
    queryName: "getGISCombiner",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetGISCombinerBlock = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string; blockId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/gis/combiner/${pathParams.blockId}`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    staleTime: 60 * 1000,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<FeatureCollection>({
    axiosConfig,
    queryName: "getGISCombinerBlock",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetGISTracker = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/gis/tracker`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<FeatureCollection>({
    axiosConfig,
    queryName: "getGISTracker",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetGISTrackerByBlock = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string; blockId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/gis/tracker-by-block/${pathParams.blockId}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<FeatureCollection>({
    axiosConfig,
    queryName: "getGISTrackerByBlock",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetGISBessEnclosure = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/gis/bess-enclosure`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<FeatureCollection>({
    axiosConfig,
    queryName: "getGISBessEnclosure",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetEquipmentAnalysisBESSBlock = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/equipment-analysis/bess-block`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.EquipmentAnalysisBESSBlock>({
    axiosConfig,
    queryName: "getEquipmentAnalysisBESSBlock",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetEquipmentAnalysisBESSPCS = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/equipment-analysis/bess-pcs`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.EquipmentAnalysisBESSPCS>({
    axiosConfig,
    queryName: "getEquipmentAnalysisBESSPCS",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetEquipmentAnalysisPCS = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/equipment-analysis/pcs`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.EquipmentAnalysisPCS>({
    axiosConfig,
    queryName: "getEquipmentAnalysisPCS",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetEquipmentAnalysisPCSv2 = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/equipment-analysis/pcs`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.EquipmentAnalysisPCSv2>({
    axiosConfig,
    queryName: "getEquipmentAnalysisPCS",
    pathParams,
    queryParams,
    queryOptions,
  });
};

export const useGetEquipmentAnalysisCombiner = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/equipment-analysis/combiner`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.EquipmentAnalysisCombiner>({
    axiosConfig,
    queryName: "getEquipmentAnalysisCombiner",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetWaterfall = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryParams?: {
    level?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/loss-waterfall`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    staleTime: 5 * 60 * 1000,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<string>({
    axiosConfig,
    queryName: "getLossWaterfall",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetResources = ({
  queryParams = {},
  queryOptions = {},
}: {
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: "/v1/development/ercot/resources",
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Resource[]>({
    axiosConfig,
    queryName: "getResources",
    pathParams: {},
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetResource = ({
  pathParams,
  queryParams = {},
  queryOptions = {},
}: {
  pathParams: { resourceId: string };
  queryParams?: object;
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/development/ercot/resources/${pathParams.resourceId}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Resource>({
    axiosConfig,
    queryName: "getResource",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetResourceNetPower = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { resourceId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/development/ercot/resources/${pathParams.resourceId}/net-power`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DataTimeSeries[]>({
    axiosConfig,
    queryName: "getResourceNetPower",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetInspections = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/quality/inspections`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Inspection[]>({
    axiosConfig,
    queryName: "getInspections",
    pathParams,
    queryParams: {},
    queryOptions,
  });
};

export const useGetObservations = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/quality/observations`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.Observation[]>({
    axiosConfig,
    queryName: "getObservations",
    pathParams,
    queryParams: {},
    queryOptions,
  });
};

export const useAddKPIAlert = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      project_id,
      alert_name,
      comparison,
      duration_value,
      kpi_type_id,
      notify,
      threshold_value,
      triggered,
    }: {
      project_id: string;
      alert_name: string;
      comparison: string | null;
      duration_value: string | null;
      kpi_type_id: string | null;
      notify: boolean;
      threshold_value: number | null | string;
      triggered: boolean | null;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "post",
        url: `${baseURL}/v1/operational/projects/${project_id}/kpi-data/kpi-alerts`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          project_id,
          alert_name,
          comparison,
          duration_value,
          kpi_type_id,
          notify,
          threshold_value,
          triggered,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIAlerts"] });
    },
  });
};

export const useUpdateKPIAlert = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      kpi_alert_id,
      project_id,
      alert_name,
      comparison,
      duration_value,
      kpi_type_id,
      notify,
      threshold_value,
      triggered,
    }: {
      kpi_alert_id: number;
      project_id: string;
      alert_name: string;
      comparison: string | null;
      duration_value: string | null;
      kpi_type_id: string | null;
      notify: boolean;
      threshold_value: number | null | string;
      triggered: boolean | null;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "put",
        url: `${baseURL}/v1/operational/projects/${project_id}/kpi-data/update-kpi-alert`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          kpi_alert_id,
          project_id,
          alert_name,
          comparison,
          duration_value,
          kpi_type_id,
          notify,
          threshold_value,
          triggered,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIAlerts"] });
    },
  });
};

export const useDeleteKPIAlert = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      project_id,
      alert_id,
    }: {
      project_id: string;
      alert_id: number;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "delete",
        url: `${baseURL}/v1/operational/projects/${project_id}/kpi-data/kpi-alerts/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { alert_id },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getKPIAlerts"] });
    },
  });
};

export const useGetTriggeredKPIAlerts = ({
  queryOptions = {},
}: {
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/kpi-data/user-triggered-alerts`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.KPIAlertProps[]>({
    axiosConfig,
    queryName: "getUserTriggeredAlerts",
    queryParams: {},
    queryOptions,
  });
};

// TODO: Refactor this to use the /v1/operational/kpi-types endpoint
export const useGetKPITypes = ({
  queryOptions = {},
}: {
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: "/v1/operational/kpi-data",
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  // TODO: Update return type
  return useCustomQuery<types.KPIInstanceProps[]>({
    axiosConfig,
    queryName: "getKPITypes",
    queryParams: {},
    queryOptions,
  });
};

export const useGetKPIType = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { kpiTypeId: number };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/kpi-types/${pathParams.kpiTypeId}`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.KPIType>({
    axiosConfig,
    queryName: "getKPIType",
    pathParams,
    queryParams: {},
    queryOptions,
  });
};

export const useGetSunburstData = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/sunburst-data`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    staleTime: 5 * 60 * 1000,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.SunburstProps>({
    axiosConfig,
    queryName: "getSunburstData",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useGetClearskyPOA = ({
  pathParams,
  queryOptions = {},
  queryParams = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
  queryParams?: {
    start?: string;
    end?: string;
  };
}) => {
  const axiosConfig = {
    url: `/v1/analytics/${pathParams.projectId}/clearsky-poa`,
    params: queryParams,
  };
  const defaultQueryOptions: Partial<UseQueryOptions> = {
    staleTime: 5 * 60 * 1000,
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.DataTimeSeries[]>({
    axiosConfig,
    queryName: "getClearskyPOA",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetKPIProjectTemplate = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string; kpiTypeNameShort: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/${pathParams.kpiTypeNameShort}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.KPIProjectTemplate>({
    axiosConfig,
    queryName: "getKPIProjectTemplate",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetKPIDevicesTemplate = ({
  pathParams,
  queryParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string; kpiTypeNameShort: string };
  queryParams?: {
    start?: string;
    end?: string;
  };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/kpi-data/${pathParams.kpiTypeNameShort}`,
    params: queryParams,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {};

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.KPIDevicesTemplate>({
    axiosConfig,
    queryName: "getKPITemplate",
    pathParams,
    queryParams: queryParams,
    queryOptions: queryOptions,
  });
};

export const useGetProjectDocuments = ({
  pathParams,
  queryOptions = {},
}: {
  pathParams: { projectId: string };
  queryOptions?: Partial<UseQueryOptions>;
}) => {
  const axiosConfig = {
    url: `/v1/operational/projects/${pathParams.projectId}/documents`,
  };

  const defaultQueryOptions: Partial<UseQueryOptions> = {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  };

  queryOptions = { ...defaultQueryOptions, ...queryOptions };

  return useCustomQuery<types.ProjectDocument[]>({
    axiosConfig,
    queryName: "getProjectDocuments",
    pathParams,
    queryParams: {},
    queryOptions: queryOptions,
  });
};

export const useUploadProjectDocument = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      projectId,
      file,
    }: {
      projectId: string;
      file: File;
    }) => {
      const token = await getToken({ template: "default" });
      const formData = new FormData();
      formData.append("file", file);

      return axios({
        method: "post",
        url: `${baseURL}/v1/operational/projects/${projectId}/documents`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["getProjectDocuments", { projectId: variables.projectId }],
      });
    },
  });
};

export const useDeleteProjectDocument = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      projectId,
      documentId,
    }: {
      projectId: string;
      documentId: string;
    }) => {
      const token = await getToken({ template: "default" });
      return axios({
        method: "delete",
        url: `${baseURL}/v1/operational/projects/${projectId}/documents/${documentId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["getProjectDocuments", { projectId: variables.projectId }],
      });
    },
  });
};

import CustomCard from "@/components/CustomCard";
import { useValidateDateRange } from "@/components/datepicker/utils";
import { AdvancedDatePicker } from "@/components/GIS";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetDevices,
  useGetPVDCCombinerFuseHealthKPIData,
} from "@/hooks/api";
import { DatetimeDataFrame, Device } from "@/hooks/types";
import { Divider, Group, SegmentedControl, Stack, Title } from "@mantine/core";
import { Data } from "plotly.js";
import { useState } from "react";
import { useParams } from "react-router-dom";

const Page = () => {
  const { projectId } = useParams();

  const { start, end } = useValidateDateRange();

  const queryStart = start ? start.toISOString() : null;
  const queryEnd = end ? end.toISOString() : null;

  const devices = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      device_type_ids: [9],
    },
  });

  const data = useGetPVDCCombinerFuseHealthKPIData({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      start: queryStart || "",
      end: queryEnd || "",
    },
  });

  return (
    <Stack p="md">
      <Title order={1}>PV DC Combiner Fuse Health</Title>
      <Group>
        <AdvancedDatePicker
          includeClearButton={false}
          defaultRange="past-week"
        />
      </Group>
      <PlotCard devices={devices} data={data} />
    </Stack>
  );
};

const PlotCard = ({
  devices,
  data,
}: {
  devices: ReturnType<typeof useGetDevices>;
  data: ReturnType<typeof useGetPVDCCombinerFuseHealthKPIData>;
}) => {
  const [plotType, setPlotType] = useState("bar");

  const parseData = (devices: Device[], data: DatetimeDataFrame) => {
    // Create an object mapping device.device_id to device.name_long
    const deviceNameMap: { [key: string]: string | null } = {};
    devices.forEach((device) => {
      deviceNameMap[device.device_id] = device.name_long;
    });

    const columnNames = data.columns.map((column) => deviceNameMap[column]);

    if (plotType === "bar") {
      const x = columnNames;
      let y = data.data[0]
        .map((_, colIndex) => data.data.map((row) => row[colIndex]))
        .map((row) => row.reduce((acc, val) => acc + val, 0));
      y = y.map((val) => val / data.data.length);

      return {
        x: x,
        y: y,
        type: "bar",
        hovertemplate: "%{y:.2%}<extra></extra>",
      } as Data;
    } else {
      const x = data.index;
      const y = columnNames;

      const z = data.data[0].map((_, colIndex) =>
        data.data.map((row) => row[colIndex])
      );

      return {
        x: x,
        y: y,
        z: z,
        type: "heatmap",
        hovertemplate: "%{x}<br>%{y}<br>%{z:.2%}<extra></extra>",
        colorbar: {
          tickformat: ",.0%",
        },
      } as Data;
    }
  };

  const parsedData =
    devices.data && data.data && parseData(devices.data, data.data);

  return (
    <BarAndHeatmapCard
      parsedData={parsedData}
      isLoading={data.isLoading}
      plotType={plotType}
      setPlotType={setPlotType}
    />
  );
};

const BarAndHeatmapCard = ({
  parsedData,
  isLoading,
  plotType,
  setPlotType,
}: {
  parsedData: Data | undefined;
  isLoading: boolean;
  plotType: string;
  setPlotType: (value: string) => void;
}) => {
  return (
    <>
      <CustomCard
        title="Combiner Fuse Health"
        style={{ height: "50vh" }}
        headerChildren={
          <>
            <SegmentedControl
              size="xs"
              value={plotType}
              onChange={setPlotType}
              data={[
                { label: "Bar", value: "bar" },
                { label: "Heatmap", value: "heatmap" },
              ]}
            />
            <Divider orientation="vertical" />
          </>
        }
      >
        <PlotlyPlot
          data={parsedData && [{ ...parsedData }]}
          layout={
            plotType === "bar"
              ? {
                  xaxis: {
                    type: "category",
                  },
                  yaxis: {
                    range: [0, 1],
                    tickformat: ",.0%",
                  },
                }
              : {}
          }
          colorscale="primary"
          isLoading={isLoading}
        />
      </CustomCard>
    </>
  );
};

export default Page;

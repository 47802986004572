import { useGetProjectDataLastUpdated } from "@/hooks/api";
import { Indicator, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import classes from "./DataStatus.module.css";

const DataStatus = ({ projectId: projectIdProp }: { projectId?: string }) => {
  let { projectId } = useParams();

  if (projectIdProp) {
    projectId = projectIdProp;
  }

  const { isLoading, isError, data } = useGetProjectDataLastUpdated({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: { enabled: !!projectId },
  });

  const { color, label } = useMemo(() => {
    if (isLoading || isError || !data) {
      return { color: "gray", label: null };
    }

    const validTimes = Object.entries(data).filter(
      ([, value]) => value !== null
    );

    if (validTimes.length === 0) {
      return {
        color: "red",
        label: "Data has not been received yet for this project.",
      };
    }

    const dateTimes = validTimes.map(([key, value]) => [key, dayjs(value)]) as [
      string,
      dayjs.Dayjs
    ][];
    dateTimes.sort((a, b) => b[1].diff(a[1]));

    const [recentKey, recentTime] = dateTimes[0];
    const fromNow = recentTime.fromNow();
    const recentTimeFormatted = recentTime.format("YYYY-MM-DD HH:mm:ss");

    if (recentKey === "time_error") {
      return {
        color: "red",
        label: `Error in data collection as of ${fromNow} (${recentTimeFormatted})`,
      };
    }

    if (recentKey === "time_empty") {
      return {
        color: "red",
        label: `No data available as of ${fromNow} (${recentTimeFormatted})`,
      };
    }

    const timeDiff = dayjs().diff(recentTime, "minute");
    const color = timeDiff > 6 ? "red" : "green";
    const label = `Received data ${fromNow} (${recentTimeFormatted})`;

    return { color, label };
  }, [isLoading, isError, data]);

  if (!projectId) {
    return null;
  }

  return (
    <Tooltip label={label} disabled={isLoading}>
      <Indicator
        size={16}
        processing={!isLoading}
        color={color}
        classNames={classes}
      />
    </Tooltip>
  );
};

export default DataStatus;

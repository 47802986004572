import { useRef } from "react";

import { Select } from "@mantine/core";
import { useDidUpdate } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";

import { useGetProjects } from "@/hooks/api";
import { useProjectDropdown } from "@/providers/ProjectDropdownProvider";

const ProjectDropdown = () => {
  const { isProjectDropdownEnabled } = useProjectDropdown();

  const { data, isLoading } = useGetProjects({
    queryParams: {
      deep: true,
    },
  });
  const { projectId } = useParams();
  const navigate = useNavigate();

  const ref = useRef<HTMLInputElement>(null);
  const blurSelect = () => {
    if (!ref.current) {
      return;
    }
    ref.current.blur();
  };

  useDidUpdate(() => {
    blurSelect();
  }, [projectId]);

  if (!projectId) {
    return null;
  }

  const handleSelectChange = (newProjectId: string | null) => {
    const updatedPath = location.pathname.replace(
      /projects\/[^/]+/,
      `projects/${newProjectId}`
    );
    navigate(`${updatedPath}${location.search}`);
  };

  return (
    <Select
      ref={ref}
      withCheckIcon={false}
      value={String(projectId)}
      onChange={handleSelectChange}
      data={data
        ?.sort((a, b) => a.name_long.localeCompare(b.name_long))
        .map((project) => ({
          value: String(project.project_id),
          label: project.name_long,
          disabled: String(project.project_id) === String(projectId),
        }))}
      disabled={!isProjectDropdownEnabled || isLoading}
      comboboxProps={{ zIndex: 500 }}
    />
  );
};

export default ProjectDropdown;

import CustomCard from "@/components/CustomCard";
import { useValidateDateRange } from "@/components/datepicker/utils";
import { AdvancedDatePicker } from "@/components/GIS";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetDevices,
  useGetEquipmentAnalysisPCSv2,
  useGetHeatmap,
  useGetProject,
} from "@/hooks/api";
import {
  ActionIcon,
  Checkbox,
  Group,
  RingProgress,
  Skeleton,
  Slider,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

const colorFromPercent = (numerator: number, denominator: number) => {
  const percent = (numerator / denominator) * 100;
  if (percent >= 90) {
    return "green";
  } else if (percent >= 75) {
    return "yellow";
  } else {
    return "red";
  }
};

const PCSHeatmap = ({
  startQuery,
  endQuery,
}: {
  startQuery: string | undefined;
  endQuery: string | undefined;
}) => {
  const { projectId } = useParams<{ projectId: string }>();

  const { data, isLoading, error } = useGetHeatmap({
    pathParams: {
      projectId: projectId || "-1",
      sensorTypeName: "pv_pcs_ac_power",
    },
    queryParams: {
      fillna_zero: false,
      start: startQuery,
      end: endQuery,
    },
  });

  return (
    <PlotlyPlot
      data={[
        {
          z: data?.z,
          x: data?.x,
          y: data?.y,
          type: "heatmap",
        },
      ]}
      colorscale={"primary"}
      isLoading={isLoading}
      error={error}
    />
  );
};

interface RingProgressCardProps {
  title: string;
  subtitle: string;
  value: number | null;
  total: number | null;
  color?: string;
  isLoading: boolean;
  size?: number;
  skeletonHeight?: number;
  skeletonMargin?: number;
}

const RingProgressCard: React.FC<RingProgressCardProps> = ({
  title,
  subtitle,
  value,
  total,
  color = "grey",
  isLoading,
  size = 150,
  skeletonHeight = 111,
  skeletonMargin = 19.5,
}) => {
  return (
    <Stack align="center" gap={0}>
      <Text>{title}</Text>
      <Text size="sm">{subtitle}</Text>
      {isLoading ? (
        <Skeleton height={skeletonHeight} circle m={skeletonMargin} />
      ) : (
        <RingProgress
          size={size}
          label={
            <Text size="sm" ta="center">
              {value !== null && total !== null
                ? `${value}/${total}`
                : "No Data"}
            </Text>
          }
          sections={[
            {
              value:
                value !== null && total !== null ? (value / total) * 100 : 0,
              color,
            },
          ]}
        />
      )}
    </Stack>
  );
};

const PCSEquipmentAnalysis = () => {
  const { projectId } = useParams();
  const [sliderValue, setSliderValue] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef<number | null>(null);
  const { start, end } = useValidateDateRange({});

  const [blockNormalize, setBlockNormalize] = useState(false);
  const [pcsNormalize, setPcsNormalize] = useState(false);

  let startQuery: string | undefined = undefined;
  let endQuery: string | undefined = undefined;

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  if (project.data) {
    if (start) {
      startQuery = start.tz(project.data.time_zone, true).toISOString();
    }
    if (end) {
      endQuery = end.tz(project.data.time_zone, true).toISOString();
    }
  }

  const devices = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { device_type_ids: [3] },
    queryOptions: { enabled: !!projectId },
  });

  const data = useGetEquipmentAnalysisPCSv2({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { start: startQuery, end: endQuery },
    queryOptions: { enabled: !!projectId },
  });

  const dataLength = data.data?.total_power_output.value.length;

  useEffect(() => {
    if (dataLength === 1) {
      setSliderValue(0);
    }
    if (isPlaying && dataLength) {
      intervalRef.current = window.setInterval(() => {
        setSliderValue((prevValue) => (prevValue + 1) % dataLength);
      }, 5000 / dataLength);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPlaying, dataLength]);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  if (project.isLoading || devices.isLoading) {
    return <PageLoader />;
  }

  let hasPCSModules = false;
  if (devices.data && devices.data.length > 0) {
    hasPCSModules = true;
  }

  const getTimeFromSliderValue = (value: number) => {
    const startOfDay = dayjs().tz(project.data?.time_zone).startOf("day");
    const currentTime = startOfDay.add(value * 5, "minute");
    return currentTime.format("HH:mm");
  };
  const blockData = blockNormalize
    ? data.data?.block_power_distribution_norm
    : data.data?.block_power_distribution;
  const pcsData = pcsNormalize
    ? data.data?.pcs_power_distribution_norm
    : data.data?.pcs_power_distribution;

  return (
    <Stack p="md">
      <Title order={1}>PV PCS Equipment Analysis</Title>
      <Skeleton visible={data.isLoading}>
        <Group>
          <AdvancedDatePicker
            maxDays={1}
            includeTodayInDateRange
            disableQuickActions
          />
          {dataLength && dataLength > 1 && (
            <>
              <Slider
                value={sliderValue}
                label={getTimeFromSliderValue(sliderValue)}
                onChange={setSliderValue}
                min={0}
                max={
                  data.data?.total_power_output.value.length
                    ? data.data.total_power_output.value.length - 1
                    : 0
                }
                step={1}
                style={{ flex: 1 }}
              />
              <ActionIcon onClick={togglePlay}>
                {isPlaying ? (
                  <IconPlayerPauseFilled size={16} />
                ) : (
                  <IconPlayerPlayFilled size={16} />
                )}
              </ActionIcon>
            </>
          )}
        </Group>
      </Skeleton>
      <Group w="100%" justify="space-evenly" align="flex-end">
        <RingProgressCard
          title="Power Output"
          subtitle="Out of nameplate capacity"
          value={
            data.data?.total_power_output.value[
              dataLength && dataLength > 1 ? sliderValue : 0
            ] ?? null
          }
          total={data.data?.total_power_output.total_nameplate ?? null}
          isLoading={data.isLoading}
          color="grey"
        />
        <RingProgressCard
          title="Blocks"
          subtitle="Generating Power"
          value={
            data.data?.generating_power_block.value[
              dataLength && dataLength > 1 ? sliderValue : 0
            ] ?? null
          }
          total={data.data?.generating_power_block.total ?? null}
          isLoading={data.isLoading}
          color={
            data.data
              ? colorFromPercent(
                  data.data.generating_power_block.value[
                    dataLength && dataLength > 1 ? sliderValue : 0
                  ],
                  data.data.generating_power_block.total
                )
              : "grey"
          }
        />
        <RingProgressCard
          title="PCSs"
          subtitle="Generating Power"
          value={
            data.data?.generating_power_pcs.value[
              dataLength && dataLength > 1 ? sliderValue : 0
            ] ?? null
          }
          total={data.data?.generating_power_pcs.total ?? null}
          isLoading={data.isLoading}
          color={
            data.data
              ? colorFromPercent(
                  data.data.generating_power_pcs.value[
                    dataLength && dataLength > 1 ? sliderValue : 0
                  ],
                  data.data.generating_power_pcs.total
                )
              : "grey"
          }
        />
        {hasPCSModules && (
          <RingProgressCard
            title="PCS Modules"
            subtitle="Generating Power"
            value={
              data.data?.generating_power_pcs_module.value[
                dataLength && dataLength > 1 ? sliderValue : 0
              ] ?? null
            }
            total={data.data?.generating_power_pcs_module.total ?? null}
            isLoading={data.isLoading}
            color={
              data.data
                ? colorFromPercent(
                    data.data.generating_power_pcs_module.value[
                      dataLength && dataLength > 1 ? sliderValue : 0
                    ],
                    data.data.generating_power_pcs_module.total
                  )
                : "grey"
            }
          />
        )}
      </Group>
      <CustomCard
        title="Block Output Distribution"
        style={{ height: "250px" }}
        headerChildren={
          <Checkbox
            label="Normalize by DC Input"
            value={blockNormalize ? "true" : "false"}
            onChange={(event) => setBlockNormalize(event.currentTarget.checked)}
          />
        }
      >
        <PlotlyPlot
          data={
            data.data && [
              {
                x: blockData?.x,
                y: blockData?.y[dataLength && dataLength > 1 ? sliderValue : 0],
                customdata: blockData?.customdata,
                type: "bar",
              },
            ]
          }
          layout={
            data.data && {
              xaxis: { type: "category", title: { text: "Block" } },
              yaxis: {
                range: [0, blockData ? blockData.yaxis_range_max * 1.05 : 1.05],
                title: { text: blockNormalize ? "Power (%)" : "Power (MW)" },
              },
            }
          }
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
      <CustomCard
        title="PCS Output Distribution"
        style={{ height: "250px" }}
        headerChildren={
          <Checkbox
            label="Normalize by DC Input"
            value={pcsNormalize ? "true" : "false"}
            onChange={(event) => setPcsNormalize(event.currentTarget.checked)}
          />
        }
      >
        <PlotlyPlot
          data={
            data.data && [
              {
                x: pcsData?.x,
                y: pcsData?.y[dataLength && dataLength > 1 ? sliderValue : 0],
                customdata: pcsData?.customdata,
                type: "bar",
              },
            ]
          }
          layout={
            data.data && {
              xaxis: { type: "category", title: { text: "PCS" } },
              yaxis: {
                range: [0, pcsData ? pcsData.yaxis_range_max * 1.05 : 1.05],
                title: { text: pcsNormalize ? "Power (%)" : "Power (MW)" },
              },
            }
          }
          isLoading={data.isLoading}
          error={data.error}
        />
      </CustomCard>
      {hasPCSModules && (
        <CustomCard
          title="PCS Module Output Distribution"
          style={{ height: "250px" }}
        >
          <PlotlyPlot
            data={
              data.data && [
                {
                  x: data.data.pcs_module_power_distribution.x,
                  y: data.data.pcs_module_power_distribution.y[
                    dataLength && dataLength > 1 ? sliderValue : 0
                  ],
                  customdata:
                    data.data.pcs_module_power_distribution.customdata,
                  type: "bar",
                },
              ]
            }
            layout={
              data.data && {
                xaxis: { type: "category", title: { text: "PCS Module" } },
                yaxis: {
                  range: [
                    0,
                    data.data.pcs_module_power_distribution.yaxis_range_max *
                      1.05,
                  ],
                  title: { text: "Power (MW)" },
                },
              }
            }
            isLoading={data.isLoading}
            error={data.error}
          />
        </CustomCard>
      )}
      <CustomCard
        title={
          "PCS Power Heatmap" +
          (dataLength && dataLength > 1 ? "" : " (Last 24 hours)")
        }
        style={{ height: "500px" }}
      >
        <PCSHeatmap startQuery={startQuery} endQuery={endQuery} />
      </CustomCard>
    </Stack>
  );
};

export default PCSEquipmentAnalysis;

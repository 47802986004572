import KPICard from "@/components/KPICard";
import { PageLoader } from "@/components/Loading";
import {
  useGetDeviceTypes,
  useGetKPIInstances,
  useGetKPISummary,
} from "@/hooks/api";
import {
  Button,
  Container,
  Grid,
  Group,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconBell, IconFilter } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ProjectKPIHome = () => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const [deviceTypeId, setDeviceTypeId] = useState<number>(
    Number(searchParams.get("deviceTypeId"))
  );

  const { data: kpiSummary, isLoading } = useGetKPISummary({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { device_type_id: deviceTypeId || undefined },
  });
  const { data: kpiInstances } = useGetKPIInstances({
    pathParams: { projectId: projectId || "-1" },
  });

  const { data: deviceTypes } = useGetDeviceTypes({});
  const filteredDeviceTypes = deviceTypes?.filter(
    (deviceType) =>
      deviceType.device_type_id !== 0 &&
      kpiInstances?.some(
        (instance) =>
          instance.kpi_type.device_type_id === deviceType.device_type_id
      )
  );

  const navigate = useNavigate();
  const handleNavigateToAlerts = () => {
    navigate(`alerts`);
  };
  if (isLoading) return <PageLoader />;

  return (
    <Container fluid pt="md">
      <Stack p="sm">
        <Title order={1}>Project KPIs</Title>
        {/* This must have a manual pr value to align with the Grid component below. */}
        <Group align="center" justify="space-between" pr={4}>
          <Group>
            <IconFilter size={24} stroke={1.5} />
            <Select
              placeholder="Select Device Type"
              data={
                filteredDeviceTypes
                  ?.filter((deviceType) => deviceType.device_type_id !== 0)
                  .map((deviceType) => ({
                    label: deviceType.name_long ?? "Unknown",
                    value: deviceType.device_type_id.toString(),
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label)) || []
              }
              value={deviceTypeId.toString()}
              onChange={(value) => setDeviceTypeId(Number(value))}
              clearable
            />
          </Group>
          <Button
            variant="light"
            onClick={handleNavigateToAlerts}
            rightSection={<IconBell size={14} />}
          >
            Manage Alerts
          </Button>
        </Group>
      </Stack>
      <Container fluid>
        <Grid gutter="sm" grow>
          {kpiSummary?.map((card, index) => (
            <Grid.Col key={index} span={{ base: 12, md: 6, lg: 6 }}>
              <KPICard {...card} />
            </Grid.Col>
          ))}
          {kpiSummary?.length === 0 && (
            <Text pt="md" size="xl">
              No KPIs available.
            </Text>
          )}
        </Grid>
      </Container>
    </Container>
  );
};

export default ProjectKPIHome;

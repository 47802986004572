import { useUser } from "@clerk/clerk-react";
import {
  Group,
  Image,
  Stack,
  Text,
  Title,
  useComputedColorScheme,
} from "@mantine/core";

const ProximalLogo = () => {
  const computedColorScheme = useComputedColorScheme();

  return (
    <Image
      src={
        computedColorScheme === "dark"
          ? "/logo_color_inverse_one_line.svg"
          : "/logo_color_one_line.svg"
      }
      my={-10}
      h="65%"
    />
  );
};

const Logo = () => {
  const { isSignedIn, user } = useUser();

  if (isSignedIn) {
    const parentCompany = user.publicMetadata.parent_company;
    switch (parentCompany) {
      case "mccarthy":
        return (
          <>
            <Image src="/logo_mccarthy.png" h="75%" />
            <Stack gap={0}>
              <Title fs="italic" lh={1} order={2} c="#db0032">
                AMP
              </Title>
              <Title lh={1} order={5}>
                Asset Management Platform
              </Title>
            </Stack>
          </>
        );
      case "catl":
        return <Image src="/logo_catl.svg" h="50%" />;
      case "excelsior":
        return <Image src="/logo_excelsior.svg" h="70%" />;
      case "cleanamps":
        return (
          <Group>
            <Text fz="xl">
              <Text fw={700} span c="green" inherit>
                Clean
              </Text>
              Amps
            </Text>
          </Group>
        );
      case "strata":
        return <Image src="/logo_strata.svg" h="70%" />;
    }
  }

  return <ProximalLogo />;
};

export default Logo;

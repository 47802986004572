import { KPICardProps } from "@/hooks/types";
import {
  Group,
  HoverCard,
  List,
  Paper,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Title,
  rem,
} from "@mantine/core";
import {
  IconCheck,
  IconExclamationMark,
  IconEyeOff,
  IconInfoCircle,
  IconLetterQ,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

const TEXT_SIZE_PRIMARY = 36;
const TEXT_SIZE_SECONDARY = 24;
const TEXT_SIZE_TERTIARY = 12;

const KPICard: React.FC<KPICardProps> = ({
  title,
  info,
  value,
  prefix,
  unit,
  change,
  quality,
  link,
  valColor,
  is_visible,
}) => {
  const colorMap = {
    good: "green",
    warning: "yellow",
    bad: "red",
  };

  const iconMap = {
    good: <IconCheck style={{ width: rem(16), height: rem(16) }} />,
    warning: (
      <IconExclamationMark style={{ width: rem(16), height: rem(16) }} />
    ),
    bad: <IconExclamationMark style={{ width: rem(16), height: rem(16) }} />,
  };

  return (
    <Paper withBorder p="xs" radius="md">
      <Stack justify="apart" gap={0}>
        <Group gap="xs">
          {!is_visible && (
            <ThemeIcon color="gray" size={20} radius="xl">
              <IconEyeOff style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          )}
          <Link to={link} style={{ color: "inherit" }}>
            {" "}
            <Title order={5}>{title}</Title>{" "}
          </Link>
          {info && (
            <HoverCard shadow="md" styles={{ dropdown: { maxWidth: "33%" } }}>
              <HoverCard.Target>
                <IconInfoCircle size={15} />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">{info}</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
          {quality && (
            <HoverCard shadow="md">
              <HoverCard.Target>
                <ThemeIcon
                  color={colorMap[quality.level]}
                  size={20}
                  radius="xl"
                >
                  <IconLetterQ style={{ width: rem(16), height: rem(16) }} />
                </ThemeIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text>{quality.message}</Text>
                <Space h="xs" />
                <List spacing="xs" size="sm" center>
                  {quality.details.map((detail, i) => (
                    <List.Item
                      key={i}
                      icon={
                        <ThemeIcon
                          color={colorMap[detail.level]}
                          size={20}
                          radius="xl"
                        >
                          {iconMap[detail.level]}
                        </ThemeIcon>
                      }
                    >
                      {detail.message}
                    </List.Item>
                  ))}
                </List>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
        </Group>

        <Group align="flex-end" gap="xs" justify="flex-start">
          <Stack justify="apart" gap={0}>
            <Group
              gap={unit === "%" || unit === "deg" || unit === null ? 0 : 5}
              align="flex-end"
            >
              {value && (
                <Text
                  fz={TEXT_SIZE_PRIMARY}
                  lh={1}
                  c={valColor ? valColor : "white"}
                >
                  {value.toLocaleString()}
                </Text>
              )}
              {unit && (
                <Text
                  fz={TEXT_SIZE_PRIMARY}
                  lh={1}
                  c={valColor ? valColor : "white"}
                >
                  {unit === "deg" ? "°" : unit}
                </Text>
              )}
            </Group>
            {prefix && (
              <Text c="dimmed" fz={TEXT_SIZE_TERTIARY}>
                {prefix}
              </Text>
            )}
          </Stack>
          <Stack justify="flex-end" gap={0} align="flex-start">
            {(change || change === 0) && (
              <Text
                fz={TEXT_SIZE_SECONDARY}
                c={change >= 0 ? "teal" : "red"}
                lh={1}
              >
                {change > 0 ? "+" : null}({change}%)
              </Text>
            )}
            {(change || change === 0) && (
              <Text c="dimmed" fz={TEXT_SIZE_TERTIARY}>
                Compared to prior day
              </Text>
            )}
          </Stack>
        </Group>
      </Stack>
    </Paper>
  );
};

// Empty KPI card for when data is loading
export const EmptyKPICard = () => {
  return (
    <KPICard
      title="Empty"
      value={1}
      prefix="Test"
      unit="Test"
      change={0}
      link=""
      is_visible={false}
    />
  );
};

export default KPICard;

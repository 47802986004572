import { PageError } from "@/components/Error";
import { PageLoader } from "@/components/Loading";
import { useGetMaximo } from "@/hooks/api";
import { useUser } from "@clerk/clerk-react";
import { Button, Group, Stack, Text, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import MaximoCard from "./MaximoCard";

const Maximo = () => {
  const { user, isLoaded } = useUser();

  const { projectId } = useParams();

  const { data, isLoading, error } = useGetMaximo({
    pathParams: { projectId: projectId || "-1" },
  });

  if (!user || !isLoaded) return <PageLoader />;

  const demo =
    typeof user.publicMetadata.demo === "boolean"
      ? user.publicMetadata.demo
      : false;

  if (isLoading) return <PageLoader />;

  if (error) return <PageError error={error} />;

  if (data?.length == 0)
    return (
      <Stack p="md">
        <Text>No Work Orders</Text>
      </Stack>
    );

  return (
    <Stack p="md">
      <Group justify="space-between" align="end">
        <Stack gap={0}>
          <Title order={1}>Maximo Work Orders</Title>
          <Text>Showing {data?.length} most recent Work Orders</Text>
        </Stack>
        <Link to={`/projects/${projectId}/maximo/by-asset`}>
          <Button variant="light" rightSection={<IconArrowRight size={14} />}>
            Browse by asset
          </Button>
        </Link>
      </Group>
      {data?.map((item) => (
        <MaximoCard key={item.id} data={item} demo={demo} />
      ))}
    </Stack>
  );
};

export default Maximo;

import {
  Button,
  Group,
  MantineSize,
  MantineStyleProps,
  Popover,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
  IconX,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DateCombobox, { DurationUnit, Limits } from "./CustomCombobox";

dayjs.extend(duration);
dayjs.extend(quarterOfYear);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

type DurationTerms =
  | "today"
  | "past-week"
  | "past-month"
  | "last-week"
  | "last-month"
  | "last-quarter"
  | "last-year"
  | "ytd";

const durationTermToName: Record<DurationTerms, string> = {
  today: "Today",
  "past-week": "Past Week",
  "past-month": "Past Month",
  "last-week": "Last Week",
  "last-month": "Last Month",
  "last-quarter": "Last Quarter",
  "last-year": "Last Year",
  ytd: "YTD",
};

const defaultLimits: Limits = {
  day: 365,
  week: 52,
  month: 12,
  quarter: 4,
  year: 1,
};

const INCREMENT_SMALL = 1;
const INCREMENT_LARGE = 3;
const PX = 0;
const STROKE = 1.5;
const QUICK_ACTION_BUTTON_VARIANT = "light";
const EMPTY_STRING = "";

export function AdvancedDatePicker({
  includeClearButton = true,
  includeIncrementButtons = true,
  includeTodayInDateRange = false,
  size = "sm",
  width,
  limits = defaultLimits,
  maxDays,
  disableInput = false,
  disableQuickActions = false,
  defaultRange,
}: {
  includeClearButton?: boolean;
  includeIncrementButtons?: boolean;
  includeTodayInDateRange?: boolean;
  size?: MantineSize | `compact-${MantineSize}`;
  width?: MantineStyleProps["w"];
  limits?: Partial<Limits>;
  maxDays?: number;
  disableInput?: boolean;
  disableQuickActions?: boolean | DurationTerms[];
  defaultRange?: DurationTerms;
}) {
  // State used to manage Popover containing DatePicker
  // NOTE: Because we are using a DatePicker instead of a DatePickerInput, we
  // don't get automatic Popover auto-close behavior after selecting a date
  // range.
  const [popoverOpened, setPopoverOpened] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // Parse start and end dates from URL search params
  const startParam = searchParams.get("start");
  const endParam = searchParams.get("end");

  // Convert start and end dates to Date objects, or null if not present
  const start = startParam ? dayjs(startParam).toDate() : null;
  const end = endParam ? dayjs(endParam).toDate() : null;

  // State to manage *just* the DatePicker UI
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    start,
    end,
  ]);

  let maxDate;
  let minDate;

  // If only one date is selected, base maxDate and minDate on that date
  // NOTE: maxDate and minDate are used for Mantine calendar UI
  if (dateRange[0] && !dateRange[1]) {
    const endDate = dayjs()
      .startOf("day")
      .subtract(includeTodayInDateRange ? 0 : 1, "day");

    if (
      dayjs(dateRange[0])
        .add(maxDays ?? 0, "day")
        .isAfter(endDate)
    ) {
      maxDate = endDate.toDate();
    } else {
      if (maxDays === undefined) {
        maxDate = endDate.toDate();
      } else {
        maxDate = dayjs(dateRange[0])
          .add(maxDays, "day")
          .subtract(1, "day")
          .toDate();
      }
    }

    if (maxDays === undefined) {
      minDate = undefined;
    } else {
      minDate = dayjs(dateRange[0])
        .subtract(maxDays ?? 0, "day")
        .add(1, "day")
        .toDate();
    }
  } else {
    minDate = undefined;
    maxDate = dayjs()
      .startOf("day")
      .subtract(includeTodayInDateRange ? 0 : 1, "day")
      .toDate();
  }

  useEffect(() => {
    // If no start and end are present, set them based on defaultRange
    if (!startParam && !endParam && defaultRange) {
      setDateRangeByTerm(defaultRange);
    } else {
      setDateRange([start, end]);
    }
  }, [startParam, endParam, defaultRange]);

  function setDateParams([start, end]: [Date | null, Date | null]) {
    if (start) {
      searchParams.set("start", dayjs(start).format("YYYY-MM-DD"));
    } else {
      searchParams.delete("start");
    }
    if (end) {
      searchParams.set("end", dayjs(end).format("YYYY-MM-DD"));
    } else {
      searchParams.delete("end");
    }

    setSearchParams(searchParams, { replace: true });
  }

  function onDateRangeChange(value: [Date | null, Date | null]) {
    setDateRange(value);
    if (value[0] && value[1]) {
      setDateParams(value);
      setPopoverOpened(false);
    }
  }

  function incrementDateRange(days: number) {
    const newStart =
      dateRange[0] && dayjs(dateRange[0]).add(days, "day").toDate();
    const newEnd =
      dateRange[1] && dayjs(dateRange[1]).add(days, "day").toDate();
    if (newStart && newEnd) {
      setDateParams([newStart, newEnd]);
    }
  }

  // Dynamically create a default quickActions array from the DurationTerms
  const defaultQuickActions: DurationTerms[] = [
    "past-week",
    "past-month",
    "last-week",
    "last-month",
    "last-quarter",
    "last-year",
    "ytd",
  ];

  // If disableQuickActions is true, set quickActions to an empty array
  // If disableQuickActions is an array, set quickActions to the difference between the defaultQuickActions and the disableQuickActions
  // Otherwise, set quickActions to the defaultQuickActions
  const quickActions =
    disableQuickActions === true
      ? []
      : Array.isArray(disableQuickActions)
      ? defaultQuickActions.filter(
          (term) => !disableQuickActions.includes(term)
        )
      : defaultQuickActions;

  function setDateRangeByTerm(term: DurationTerms) {
    let today = dayjs().startOf("day");
    if (!includeTodayInDateRange) {
      today = today.subtract(1, "day");
    }
    let start = null;
    let end = null;

    switch (term) {
      case "today":
        start = today.toDate();
        end = today.toDate();
        break;
      case "past-week":
        start = today.subtract(1, "week").add(1, "day").toDate();
        end = today.toDate();
        break;
      case "past-month":
        start = today.subtract(1, "month").add(1, "day").toDate();
        end = today.toDate();
        break;
      case "last-week":
        start = today.subtract(1, "week").startOf("week").toDate();
        end = today.subtract(1, "week").endOf("week").toDate();
        break;
      case "last-month":
        start = today.subtract(1, "month").startOf("month").toDate();
        end = today.subtract(1, "month").endOf("month").toDate();
        break;
      case "last-quarter":
        start = today.subtract(1, "quarter").startOf("quarter").toDate();
        end = today.subtract(1, "quarter").endOf("quarter").toDate();
        break;
      case "last-year":
        start = today.subtract(1, "year").startOf("year").toDate();
        end = today.subtract(1, "year").endOf("year").toDate();
        break;
      case "ytd":
        start = today.startOf("year").toDate();
        end = today.toDate();
        break;
    }

    setPopoverOpened(false);
    setDateParams([start, end]);
  }

  const handleComboboxSubmit = (value: string) => {
    const match = value.match(/^(\d+)\s*([a-zA-Z]*)$/);
    if (match) {
      let number = parseInt(match[1], 10);
      let unit = match[2] as DurationUnit;

      if (unit === "week") {
        unit = "day";
        number *= 7;
      }
      if (unit === "quarter") {
        unit = "month";
        number *= 3;
      }

      let today = dayjs().startOf("day");
      if (!includeTodayInDateRange) {
        today = today.subtract(1, "day");
      }

      const newStart = today.subtract(number, unit).add(1, "day").toDate();
      const newEnd = today.toDate();
      setDateParams([newStart, newEnd]);
      setPopoverOpened(false);
    }
  };

  const mergedLimits = { ...defaultLimits, ...limits };

  const empty = start === null && end === null;

  // Add this helper function to format the date display
  function formatDateDisplay() {
    if (empty) {
      if (maxDays === 1) {
        return "Select Date";
      } else {
        return "Select Date Range";
      }
    }

    if (maxDays === 1) {
      return start
        ? dayjs(start).format("YYYY/MM/DD")
        : EMPTY_STRING.padEnd(10, "\u00A0");
    }

    return (
      (start && dayjs(start).format("YYYY/MM/DD")) +
      " - " +
      (end
        ? dayjs(end).format("YYYY/MM/DD")
        : EMPTY_STRING.padEnd(10, "\u00A0"))
    );
  }

  return (
    <Button.Group w={width}>
      {includeIncrementButtons && (
        <>
          <Tooltip
            label={`Shift ${
              maxDays === 1 ? "date" : "range"
            } back by ${INCREMENT_LARGE} days`}
            disabled={empty}
          >
            <Button
              size={size}
              px={PX}
              variant="default"
              disabled={empty}
              onClick={() => {
                incrementDateRange(-INCREMENT_LARGE);
              }}
            >
              <IconChevronsLeft stroke={STROKE} />
            </Button>
          </Tooltip>
          <Tooltip
            label={`Shift ${
              maxDays === 1 ? "date" : "range"
            } back by ${INCREMENT_SMALL} day`}
            disabled={empty}
          >
            <Button
              size={size}
              px={PX}
              variant="default"
              disabled={empty}
              onClick={() => {
                incrementDateRange(-INCREMENT_SMALL);
              }}
            >
              <IconChevronLeft stroke={STROKE} />
            </Button>
          </Tooltip>
        </>
      )}
      <Popover opened={popoverOpened} onChange={setPopoverOpened}>
        <Popover.Target>
          <Button
            size={size}
            flex={width ? 1 : undefined}
            px={"xs"}
            variant="default"
            ff="monospace"
            disabled={disableInput}
            onClick={() => setPopoverOpened((o) => !o)}
          >
            <Text w="23ch" size={size}>
              {formatDateDisplay()}
            </Text>
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack gap="xs">
            <Group gap="xs">
              {maxDays === 1 ? (
                <DatePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  value={dateRange[0]}
                  onChange={(value) => onDateRangeChange([value, value])}
                />
              ) : (
                <DatePicker
                  type="range"
                  allowSingleDateInRange
                  minDate={minDate}
                  maxDate={maxDate}
                  value={dateRange}
                  onChange={onDateRangeChange}
                />
              )}
              <Stack gap="xs" align="center">
                {quickActions.map((term) => (
                  <Button
                    key={term}
                    size="compact-xs"
                    variant={QUICK_ACTION_BUTTON_VARIANT}
                    fullWidth
                    onClick={() => setDateRangeByTerm(term)}
                  >
                    {durationTermToName[term]}
                  </Button>
                ))}
              </Stack>
            </Group>
            <DateCombobox
              limits={mergedLimits}
              onOptionSubmit={handleComboboxSubmit} // Pass the handler to the DateCombobox
              activate={popoverOpened} // Add this prop to control activation
            />
          </Stack>
        </Popover.Dropdown>
      </Popover>
      {includeClearButton && (
        <Button
          size={size}
          px={PX}
          variant="default"
          disabled={empty}
          onClick={() => {
            setDateParams([null, null]);
          }}
        >
          <IconX stroke={1} />
        </Button>
      )}
      {includeIncrementButtons && (
        <>
          <Tooltip
            label={`Shift ${
              maxDays === 1 ? "date" : "range"
            } forward by ${INCREMENT_SMALL} day`}
            disabled={empty}
          >
            <Button
              size={size}
              px={PX}
              variant="default"
              disabled={empty}
              onClick={() => {
                incrementDateRange(INCREMENT_SMALL);
              }}
            >
              <IconChevronRight stroke={STROKE} />
            </Button>
          </Tooltip>
          <Tooltip
            label={`Shift ${
              maxDays === 1 ? "date" : "range"
            } forward by ${INCREMENT_LARGE} days`}
            disabled={empty}
          >
            <Button
              size={size}
              px={PX}
              variant="default"
              disabled={empty}
              onClick={() => {
                incrementDateRange(INCREMENT_LARGE);
              }}
            >
              <IconChevronsRight stroke={STROKE} />
            </Button>
          </Tooltip>
        </>
      )}
    </Button.Group>
  );
}

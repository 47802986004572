import { GISContext } from "@/contexts/GISContext";
import { useGetDevice, useGetDevices, useGetProject } from "@/hooks/api";
import * as gisUtils from "@/utils/GIS";
import { findBoundingBox } from "@/utils/GIS";
import {
  Box,
  LoadingOverlay,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { IconDatabaseOff, IconFlag } from "@tabler/icons-react";
import { FeatureCollection } from "geojson";
import { useContext } from "react";
import Map, { Layer, Marker, Source } from "react-map-gl";
import { useParams } from "react-router-dom";
import { MapSettings } from "./GIS";

interface EventGISCardProps {
  deviceId: string;
}

const useProjectId = () => useParams().projectId || "-1";

const EventGISCard = (deviceId: EventGISCardProps) => {
  const projectId = useProjectId();
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme("dark");
  const context = useContext(GISContext);
  const blankMapStyle = gisUtils.useBlankMapStyle();

  const {
    data: deviceData,
    isLoading: isDeviceLoading,
    error: deviceError,
  } = useGetDevice({
    pathParams: { projectId, deviceId: deviceId.deviceId },
  });

  const {
    data: projectData,
    isLoading: isProjectLoading,
    error: projectError,
  } = useGetProject({
    pathParams: { projectId },
  });

  const devices = useGetDevices({
    pathParams: { projectId: projectId || "-1" },
    queryParams: {
      device_type_ids: [6],
    },
  });

  const { data: parentDeviceData } = useGetDevice({
    pathParams: {
      projectId,
      deviceId: deviceData?.parent_device_id?.toString() || "-1",
    },
  });

  let coordinates: [number, number] = [0, 0];
  if (!projectData?.has_pv_pcs_layout) {
    coordinates = [
      parentDeviceData?.point?.coordinates[0] || 0,
      parentDeviceData?.point?.coordinates[1] || 0,
    ];
  } else {
    coordinates = [
      deviceData?.point?.coordinates[0] || 0,
      deviceData?.point?.coordinates[1] || 0,
    ];
  }

  const isLoading = isDeviceLoading || devices.isLoading || isProjectLoading;
  const isError = deviceError || projectError || devices.isError;

  if (!context) {
    throw new Error("GISContext is not provided");
  }

  const { showLabels, showSatellite } = context;

  const getGoogleMapsDirectionsUrl = () => {
    return `https://www.google.com/maps/dir/?api=1&destination=${coordinates[1]},${coordinates[0]}&travelmode=driving`;
  };

  const handleLeftClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(getGoogleMapsDirectionsUrl(), "_blank");
  };

  if (isLoading) {
    return <LoadingOverlay visible={true} />;
  }
  if (isError) {
    return <IconDatabaseOff size={48} strokeWidth={1} />;
  }

  const filteredData = {
    type: "FeatureCollection",
    features: devices.data?.map((device) => {
      return {
        type: "Feature",
        geometry: device.polygon,
        properties: {
          name: device.name_long,
        },
      };
    }),
  } as FeatureCollection;

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <Map
        initialViewState={{
          bounds: findBoundingBox(filteredData),
          fitBoundsOptions: {
            padding: 35,
          },
        }}
        style={{
          borderBottomLeftRadius: "inherit",
          borderBottomRightRadius: "inherit",
        }}
        mapStyle={
          gisUtils.mapStyle({
            satellite: showSatellite,
            theme: computedColorScheme,
          }) ?? blankMapStyle
        }
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
      >
        <Source id="data" type="geojson" data={filteredData}>
          <Layer
            id="data"
            type="fill"
            paint={{
              "fill-color": theme.colors.gray[5], // Custom fill color
              "fill-opacity": 0.7,
            }}
          />
          {showLabels && (
            <Layer {...gisUtils.layerLabel({ textField: "name" })} />
          )}
        </Source>
        <Marker
          key="Key!"
          longitude={coordinates[0]}
          latitude={coordinates[1]}
          anchor="bottom"
        >
          <div onClick={handleLeftClick} style={{ cursor: "pointer" }}>
            <IconFlag
              color={"#ff0000"}
              fill={"#ff0000"}
              style={{ pointerEvents: "auto" }}
            />
          </div>
        </Marker>
      </Map>
      <Box
        style={{ position: "absolute", bottom: 0, left: 0, zIndex: 1 }}
        px="md"
        py="xl"
      >
        <MapSettings />
      </Box>
    </div>
  );
};

export default EventGISCard;

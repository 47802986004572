import CustomCard from "@/components/CustomCard";
import { NoData, PageError } from "@/components/Error";
import { PageLoader } from "@/components/Loading";
import { useTipsPersonalPortfolio } from "@/components/Tips";
import { useGetProjects } from "@/hooks/api";
import { Project } from "@/hooks/types";
import { Stack, Table, Title } from "@mantine/core";
import { Link } from "react-router-dom";

const PortfolioList = () => {
  useTipsPersonalPortfolio();

  const { data, isLoading, error } = useGetProjects({
    queryParams: {
      deep: true,
    },
  });

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  if (!data) {
    return <NoData />;
  }

  // Sort data by data.name_short
  data.sort((a, b) => (a.name_short > b.name_short ? 1 : -1));

  const pvProjects = data.filter(
    (project) =>
      project.project_type && project.project_type.name_short === "pv"
  );
  const bessProjects = data.filter(
    (project) =>
      project.project_type && project.project_type.name_short === "bess"
  );
  const pvsProjects = data.filter(
    (project) =>
      project.project_type && project.project_type.name_short === "pvs"
  );

  return (
    <Stack p="md">
      <Title order={1}>Portfolio</Title>
      <PVTable data={pvProjects} />
      <BESSTable data={bessProjects} />
      <PVSTable data={pvsProjects} />
    </Stack>
  );
};

const PVTable = ({ data }: { data: Project[] }) => {
  // If data is empty, return null
  if (data.length === 0) {
    return null;
  }

  return (
    <CustomCard title="PV">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>POI (MW)</Table.Th>
            <Table.Th>AC Capacity (MW)</Table.Th>
            <Table.Th>DC Capacity (MW)</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((project) => (
            <Table.Tr key={project.project_id}>
              <Table.Td>
                <Link
                  to={`/projects/${project.project_id}`}
                  style={{ color: "inherit" }}
                >
                  {project.name_long}
                </Link>
              </Table.Td>
              <Table.Td>{project.poi.toFixed(0)}</Table.Td>
              <Table.Td>{project.capacity_ac?.toFixed(0)}</Table.Td>
              <Table.Td>{project.capacity_dc?.toFixed(0)}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </CustomCard>
  );
};

const BESSTable = ({ data }: { data: Project[] }) => {
  // If data is empty, return null
  if (data.length === 0) {
    return null;
  }

  return (
    <CustomCard title="BESS">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>POI (MW-AC)</Table.Th>
            <Table.Th>Power Capacity (MW-AC)</Table.Th>
            <Table.Th>Duration</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((project) => (
            <Table.Tr key={project.project_id}>
              <Table.Td>
                <Link
                  to={`/projects/${project.project_id}`}
                  style={{ color: "inherit" }}
                >
                  {project.name_long}
                </Link>
              </Table.Td>
              <Table.Td>{project.poi.toFixed(0)}</Table.Td>
              <Table.Td>{project.capacity_power?.toFixed(0)}</Table.Td>
              <Table.Td>{project.duration}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </CustomCard>
  );
};

const PVSTable = ({ data }: { data: Project[] }) => {
  // If data is empty, return null
  if (data.length === 0) {
    return null;
  }

  return (
    <CustomCard title="PV+BESS">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>POI (MW)</Table.Th>
            <Table.Th>PV AC Capacity (MW)</Table.Th>
            <Table.Th>PV DC Capacity (MW)</Table.Th>
            <Table.Th>Battery Power Capacity (MW)</Table.Th>
            <Table.Th>Duration</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.map((project) => (
            <Table.Tr key={project.project_id}>
              <Table.Td>
                <Link
                  to={`/projects/${project.project_id}`}
                  style={{ color: "inherit" }}
                >
                  {project.name_long}
                </Link>
              </Table.Td>
              <Table.Td>{project.poi.toFixed(0)}</Table.Td>
              <Table.Td>{project.capacity_ac?.toFixed(0)}</Table.Td>
              <Table.Td>{project.capacity_dc?.toFixed(0)}</Table.Td>
              <Table.Td>{project.capacity_power?.toFixed(0)}</Table.Td>
              <Table.Td>{project.duration}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </CustomCard>
  );
};

export default PortfolioList;

import { MaximoWorkOrder } from "@/hooks/types";
import {
  Badge,
  Button,
  Card,
  DefaultMantineColor,
  Group,
  rem,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconClock,
  IconExternalLink,
  IconMapPinFilled,
  IconSettingsFilled,
  IconUsers,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const ICON_PROPS = {
  style: {
    width: rem(24),
    height: rem(24),
  },
  stroke: 1.5,
};

const GAP = "xs";

const MaximoCard = ({
  data,
  demo = false,
}: {
  data: MaximoWorkOrder;
  demo?: boolean;
}) => {
  // Function to determine the status color based on the work order status
  const getStatusColor = (status: string): DefaultMantineColor => {
    const statusColors: Record<string, DefaultMantineColor> = {
      Completed: "green",
      Closed: "green",
      Approved: "green",
      Canceled: "red",
      "New Work Order": "yellow",
    };
    return statusColors[status] || "gray"; // Default to gray if status not found
  };

  // Render asset information
  const renderAssets = () => {
    if (!data.asset && !data.multi_asset) {
      return <Text c="red">NO ASSET</Text>;
    }
    return (
      <Stack gap="xs">
        {data.multi_asset?.map((asset) => (
          <Text key={asset.number}>
            {data.multi_asset && data.multi_asset.length > 1 ? `- ` : ""}(
            {asset.number}) {asset.description}
          </Text>
        ))}
        {data.asset && !data.multi_asset && (
          <Text>
            ({data.asset.number}) {data.asset.description}
          </Text>
        )}
      </Stack>
    );
  };

  return (
    <Card withBorder shadow="sm" p="md">
      <Stack gap={GAP}>
        <Group gap={GAP}>
          <Badge variant="light" size="lg" color={getStatusColor(data.status)}>
            {data.status}
          </Badge>
          <Text fw={500} size="xl">
            ({data.number}) {data.description}
          </Text>
          <Link
            to={`https://mcc.softwrench2.com/panel/work-order/sm/edit/${data.id}`}
            target="_blank"
          >
            <Button
              variant="light"
              size="compact-xs"
              rightSection={<IconExternalLink size={14} />}
            >
              Softwrench
            </Button>
          </Link>
        </Group>
        <Text size="lg">
          <div dangerouslySetInnerHTML={{ __html: data.description_long }} />
        </Text>
        <Group gap={GAP}>
          <IconMapPinFilled {...ICON_PROPS} />
          <Text>
            ({data.location.number}) {data.location.description}
          </Text>
        </Group>
        <Group gap={GAP} align="start">
          <IconSettingsFilled {...ICON_PROPS} />
          {renderAssets()} {/* Render asset information */}
        </Group>
        <Group gap={GAP}>
          <IconUsers {...ICON_PROPS} />
          <Text>Reported By</Text>
          <Badge variant="default">
            {demo ? "John Smith" : data.reported_by}
          </Badge>
          {data.assigned_to && (
            <>
              <Text>Assigned To</Text>
              <Badge variant="default">
                {demo ? "Jane Smith" : data.assigned_to}
              </Badge>
            </>
          )}
        </Group>
        {data.reported_date && (
          <Group gap={GAP}>
            <IconClock {...ICON_PROPS} />
            <Text>
              {dayjs(data.reported_date).format("YYYY-MM-DD HH:mm:ss")}
            </Text>
          </Group>
        )}
      </Stack>
    </Card>
  );
};

export default MaximoCard;

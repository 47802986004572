import {
  HoverCard,
  Paper,
  Stack,
  Switch,
  Text,
  useComputedColorScheme,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import {
  IconBattery4,
  IconSolarElectricity,
  IconSolarPanel,
} from "@tabler/icons-react";
import Map, { Layer, Marker, Source } from "react-map-gl";
import { Link } from "react-router-dom";

import { NoData, PageError } from "@/components/Error";
import { PageLoader } from "@/components/Loading";

import { useGetProjects } from "@/hooks/api";

const tileUrl = (tile: string): string => {
  const appid = import.meta.env.VITE_OPENWEATHERMAP_APP_ID;
  return `https://tile.openweathermap.org/map/${tile}/{z}/{x}/{y}.png?appid=${appid}`;
};

const PortfolioMap = () => {
  const computedColorScheme = useComputedColorScheme("dark");

  const [showClouds, setShowClouds] = useLocalStorage({
    key: "show-clouds",
    defaultValue: false,
  });
  const [showPrecipitation, setShowPrecipitation] = useLocalStorage({
    key: "show-precipitation",
    defaultValue: false,
  });

  const { data, isLoading, error } = useGetProjects({
    queryParams: { deep: true },
  });

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  if (!data) {
    return <NoData />;
  }

  const cloudTileURL = tileUrl("clouds_new");
  const precipitationTileURL = tileUrl("precipitation_new");

  const icon_style = {
    width: "25px",
    height: "25px",
    color: "var(--mantine-primary-color-filled)",
  };

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <Map
        initialViewState={{
          bounds: [-124.4, 24.54, -66.93, 49.38], // USA lower 48 bounds
          fitBoundsOptions: {
            padding: 50,
          },
        }}
        style={{
          borderBottomLeftRadius: "inherit",
          borderBottomRightRadius: "inherit",
        }}
        mapStyle={
          computedColorScheme === "dark"
            ? "mapbox://styles/mapbox/dark-v9"
            : "mapbox://styles/mapbox/light-v9"
        }
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
      >
        {showClouds && (
          <Source
            id="weather-data"
            type="raster"
            tiles={[cloudTileURL]} // Use the template URL here
            tileSize={256} // Typically, slippy map tiles are 256x256
          >
            <Layer id="weather-layer" type="raster" source="weather-data" />
          </Source>
        )}
        {showPrecipitation && (
          <Source
            id="precipitation-data"
            type="raster"
            tiles={[precipitationTileURL]} // Use the template URL here
            tileSize={256} // Typically, slippy map tiles are 256x256
          >
            <Layer
              id="precipitation-layer"
              type="raster"
              source="precipitation-data"
            />
          </Source>
        )}
        {data.map((project) => (
          <Marker
            key={project.project_id}
            longitude={project.point.coordinates[0]}
            latitude={project.point.coordinates[1]}
          >
            <Link to={`/projects/${project.project_id}`}>
              <HoverCard shadow="md">
                <HoverCard.Target>
                  <div style={{ transform: "translate(-50%, -50%)" }}>
                    {(() => {
                      switch (project.project_type?.name_short) {
                        case "pv":
                          return <IconSolarPanel style={icon_style} />;
                        case "bess":
                          return <IconBattery4 style={icon_style} />;
                        case "pvs":
                          return <IconSolarElectricity style={icon_style} />;
                        default:
                          return <IconSolarPanel style={icon_style} />;
                      }
                    })()}
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text>{project.name_long}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Link>
          </Marker>
        ))}
      </Map>
      <div style={{ position: "absolute", top: 16, right: 16, zIndex: 1 }}>
        <Paper p={"xs"} radius={"md"}>
          <Stack>
            <Switch
              checked={showClouds}
              onChange={() => setShowClouds((prev) => !prev)}
              label="Show Cloud Overlay"
            />
            <Switch
              checked={showPrecipitation}
              onChange={() => setShowPrecipitation((prev) => !prev)}
              label="Show Precipitation Overlay"
            />
          </Stack>
        </Paper>
      </div>
    </div>
  );
};

export default PortfolioMap;

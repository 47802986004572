import { useMantineTheme, Group, Text } from "@mantine/core";
import { IconBackhoe, IconBulldozer } from "@tabler/icons-react";

const ConstructionBanner = ({ radius = true }: { radius?: boolean }) => {
  const theme = useMantineTheme();

  let borderRadius;
  if (radius) {
    borderRadius = "0.5rem";
  } else {
    borderRadius = "0";
  }

  return (
    <Group
      justify="center"
      p="sm"
      style={{ background: theme.colors.yellow[3], borderRadius: borderRadius }}
    >
      <IconBackhoe color="black" />
      <Text c="black">
        NOTE: This page is under construction and could see significant changes
        in the future.
      </Text>
      <IconBulldozer color="black" />
    </Group>
  );
};

export default ConstructionBanner;

import { useClerk, useUser } from "@clerk/clerk-react";
import { Avatar, Menu, rem, UnstyledButton } from "@mantine/core";
import {
  IconCode,
  IconLogout2,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

const UserDropdown = () => {
  const { user } = useUser();
  const { signOut } = useClerk();
  const initials =
    user?.firstName && user?.lastName
      ? `${user.firstName[0]}${user.lastName[0]}`
      : user?.firstName?.[0] || "";

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <UnstyledButton>
          <Avatar
            src={user?.hasImage ? user?.imageUrl : undefined}
            alt={user?.fullName || "User avatar"}
            radius="xl"
          >
            {initials}
          </Avatar>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown style={{ zIndex: 800 }}>
        <Menu.Item
          component={Link}
          to="/account"
          leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}
        >
          Account
        </Menu.Item>
        <Menu.Item
          component={Link}
          to="/settings"
          leftSection={
            <IconSettings style={{ width: rem(14), height: rem(14) }} />
          }
        >
          Settings
        </Menu.Item>
        <Menu.Item
          component={Link}
          to="/api"
          leftSection={<IconCode style={{ width: rem(14), height: rem(14) }} />}
        >
          API
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={() => signOut()}
          leftSection={
            <IconLogout2 style={{ width: rem(14), height: rem(14) }} />
          }
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserDropdown;

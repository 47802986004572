import KPIProjectTemplate from "./KPIProjectTemplate";

const Page = () => {
  return (
    <KPIProjectTemplate
      kpiTypeId={16}
      cardTitle="Average SOC"
      plotLayout={{ yaxis: { range: [0, 1], tickformat: ".0%" } }}
    />
  );
};

export default Page;

import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import {
  ActionIcon,
  Button,
  ComboboxItem,
  Group,
  Modal,
  OptionsFilter,
  Paper,
  Select,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCaretDown, IconCaretUp } from "@tabler/icons-react";

import CustomCard from "@/components/CustomCard";
import EventGISCard from "@/components/EventGISCard";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";

import {
  useGetEventLosses,
  useGetEvents,
  useGetFailureModes,
  useGetProject,
  useGetRootCauses,
  useGetTimeSeries,
  useUpdateRootCause,
} from "@/hooks/api";

import DeviceEventsTimeline from "./DeviceEventsTimeline";
import EventStepper from "./EventStepper";
import ScheduledMaintenanceCalendar from "./ScheduledMaintenanceCalendar";

import * as types from "@/hooks/types";
import { useProjectDropdownToggle } from "@/hooks/custom";

const getNextScheduledMaintenance = (date: Dayjs) => {
  let nextDate = date.add(1, "day");
  while (![1, 15].includes(nextDate.date())) {
    nextDate = nextDate.add(1, "day");
  }
  return nextDate;
};

const EventPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [expandCalculation, setExpandCalculation] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [selectedRootCause, setSelectedRootCause] = useState<number | null>(
    null
  );

  const eventId = parseInt(
    new URLSearchParams(location.search).get("eventId") || "-1"
  );

  useProjectDropdownToggle();

  const { data: project, isLoading: isProjectLoading } = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const { data: eventData, isLoading: isEventDataLoading } = useGetEvents({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { event_ids: [eventId], open: false },
  });
  const event = eventData?.[0];

  const { data: eventsHistorical, isLoading: isEventsHistoricalLoading } =
    useGetEvents({
      pathParams: { projectId: projectId || "-1" },
      queryParams: {
        device_id: event?.device_id?.toString() || "-1",
        open: false,
      },
    });

  const { data: eventLosses, isLoading: isEventLossesLoading } =
    useGetEventLosses({
      pathParams: { projectId: projectId || "-1" },
      queryParams: { event_ids: [eventId] },
    });

  const { data: failureModes } = useGetFailureModes({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { failure_mode_ids: [event?.failure_mode_id || 1] },
  });

  const { data: rootCauses, isLoading: isRootCausesLoading } = useGetRootCauses(
    {
      pathParams: { projectId: projectId || "-1" },
      queryParams: { device_type_ids: [event?.device?.device_type_id || -1] },
    }
  );

  const mutation = useUpdateRootCause();
  const updateRootCause = (rootCauseId: number | null) => {
    mutation.mutate({
      project_id: projectId || "-1",
      event_id: eventId,
      root_cause_id: rootCauseId !== null ? rootCauseId : undefined,
    });
  };

  useEffect(() => {
    if (event) {
      setSelectedRootCause(event.root_cause_id);
    }
  }, [event]);

  const sensorTypeDict = [
    { id: 2, name: "pv_pcs_ac_power" },
    { id: 3, name: "pv_pcs_module_ac_power" },
    { id: 5, name: "meter_active_power" },
    { id: 9, name: "pv_dc_combiner_current" },
  ];
  const sensorType = sensorTypeDict.find(
    (d) => d.id === event?.device?.device_type_id
  )?.name;

  const { data: timeSeries, isLoading: isTimeSeriesLoading } = useGetTimeSeries(
    {
      pathParams: { projectId: projectId || "-1" },
      queryParams: {
        device_ids: [event?.device_id || -1],
        sensor_type_name_shorts: [sensorType || ""],
        start: dayjs(event?.time_start)
          .startOf("day")
          .format("YYYY-MM-DD 00:00:00"),
        end: dayjs(event?.time_start)
          .startOf("day")
          .add(1, "day")
          .format("YYYY-MM-DD 00:00:00"),
      },
    }
  );

  const isLoading =
    isProjectLoading ||
    isEventDataLoading ||
    isEventsHistoricalLoading ||
    isEventLossesLoading ||
    isRootCausesLoading;

  if (isLoading) return <PageLoader />;

  const deviceNameFull = event
    ? `${event.device_name_full}`
    : "Device (unknown)";

  const dailyLoss = eventLosses?.length
    ? eventLosses
        .filter((e) => e.event_loss_type_id === 2)
        .reduce((acc, e) => acc + e.loss, 0) /
      eventLosses.filter((e) => e.event_loss_type_id === 2).length
    : 0;

  const today = dayjs();
  const nextMaintenance = getNextScheduledMaintenance(today);
  const daysUntilMaintenance = nextMaintenance.diff(today, "days");
  const noActionLoss = daysUntilMaintenance * dailyLoss;

  const flightCost = 500;
  const lodgingCost = 1000;
  const actionLoss = flightCost + lodgingCost + 2 * dailyLoss;
  const actionBenefit = noActionLoss - actionLoss;
  const offTime = dayjs(event?.time_start)
    .tz(project?.time_zone)
    .format("YYYY-MM-DD HH:mm:ssZ");

  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const filtered = (options as ComboboxItem[]).filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase().trim())
    );
    return filtered.sort((a, b) => a.label.localeCompare(b.label));
  };

  const ConfirmRootModal = () => (
    <Modal
      opened={opened}
      onClose={() => {
        close();
        setSelectedRootCause(event?.root_cause_id ?? null);
      }}
      title={`Confirm Root Cause: ${
        rootCauses?.find((fm) => fm.root_cause_id === selectedRootCause)
          ?.name_long ?? "Unknown"
      }`}
      transitionProps={{ transition: "rotate-left" }}
    >
      <Stack>
        <Text>Are you sure you want to change the root cause?</Text>
        <Group grow>
          <Button
            onClick={() => {
              close();
              setSelectedRootCause(event?.root_cause_id ?? null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateRootCause(selectedRootCause);
              close();
              navigate(0);
            }}
          >
            Confirm
          </Button>
        </Group>
      </Stack>
    </Modal>
  );

  return (
    <Stack h="100%" w="100%" p="sm">
      <ConfirmRootModal />
      <Group align="flex-start" justify="center">
        <Stack flex={4} h="100%">
          <Stack align="center">
            <Title order={1}>
              {project ? `${deviceNameFull} Failure` : "Loading..."}
            </Title>
            <Title order={4}>
              Failure Mode: {failureModes?.[0]?.name_long}
            </Title>
            <Group w="50%" justify="center">
              <Title order={4}>Root Cause:</Title>
              <Select
                w="60%"
                data={rootCauses?.map((fm) => ({
                  value: fm.root_cause_id.toString(),
                  label: fm.name_long,
                }))}
                value={selectedRootCause?.toString()}
                onChange={(value) => {
                  setSelectedRootCause(value ? parseInt(value) : null);
                  if (value !== event?.root_cause_id?.toString()) {
                    open();
                  }
                }}
                clearable
                searchable
                nothingFoundMessage="Nothing found..."
                filter={optionsFilter}
              />
            </Group>
            {dailyLoss > 0 ? (
              <Text>${dailyLoss.toFixed(2)} revenue loss per day</Text>
            ) : (
              <Text>Daily revenue loss pending.</Text>
            )}
            {event && <EventStepper event={event} />}
            <Paper p="md" withBorder h="100%">
              <>
                <Text fw={700}>ProxiBot Recommendation</Text>
                <Group justify="space-between">
                  {event?.time_end == null ? (
                    actionBenefit > 0 ? (
                      <Text>
                        Create Ticket: Repair {deviceNameFull} immediately.
                      </Text>
                    ) : (
                      <Text>
                        Create Ticket: Repair {deviceNameFull} on next scheduled
                        maintenance.
                      </Text>
                    )
                  ) : (
                    <Text>No issue at this time.</Text>
                  )}
                  <Tooltip
                    disabled={dailyLoss !== 0}
                    label="Disabled: revenue loss pending. This field will update within 6 hours."
                  >
                    <ActionIcon
                      onClick={() => setExpandCalculation(!expandCalculation)}
                      disabled={!dailyLoss || dailyLoss === 0}
                    >
                      {expandCalculation ? <IconCaretUp /> : <IconCaretDown />}
                    </ActionIcon>
                  </Tooltip>
                </Group>
                {dailyLoss > 0 && expandCalculation && (
                  <>
                    <br />
                    <Text fw={700}>Calculation</Text>
                    <Text>
                      Next scheduled maintenance:{" "}
                      {nextMaintenance.format("MMMM D")} ({daysUntilMaintenance}{" "}
                      days) -&gt; ${noActionLoss.toFixed(2)} loss
                    </Text>
                    <Text>
                      Unscheduled visit: ASAP (2 days) -&gt; ${flightCost}{" "}
                      flight + ${lodgingCost} lodging + $
                      {(2 * dailyLoss).toFixed(2)} loss = $
                      {actionLoss.toFixed(2)}
                    </Text>
                    {actionBenefit > 0 ? (
                      <Text ta="center" fw={700} fz="lg" c="green">
                        ${actionBenefit.toFixed(2)} Benefit
                      </Text>
                    ) : (
                      <Text ta="center" fw={700} fz="lg">
                        No mobilization benefit.
                      </Text>
                    )}
                  </>
                )}
              </>
            </Paper>
            {dailyLoss > 0 && expandCalculation && (
              <>
                <Title order={3}>Scheduled Maintenance Visits</Title>
                <Paper withBorder>
                  <ScheduledMaintenanceCalendar />
                </Paper>
              </>
            )}
          </Stack>
          <Group grow p="xs" h="100%">
            <CustomCard title="Event Location" style={{ height: "100%" }} fill>
              <EventGISCard deviceId={event?.device_id.toString() || "-1"} />
            </CustomCard>
            <CustomCard
              title={
                event?.device?.device_type_id === 9
                  ? "Device Current"
                  : "Device Power"
              }
              style={{ height: "100%" }}
            >
              <PlotlyPlot
                data={timeSeries?.map((d) => ({
                  x: d.x,
                  y: d.y,
                  name: d.name,
                  hoverlabel: { namelength: -1 },
                }))}
                layout={{
                  yaxis: {
                    title:
                      event?.device?.device_type_id === 9
                        ? "Current (A)"
                        : "Power (MW)",
                    side: "left",
                  },
                  shapes: [
                    {
                      type: "line",
                      x0: offTime,
                      x1: offTime,
                      y0: 0,
                      y1: 1,
                      xref: "x",
                      yref: "paper",
                      line: {
                        color: "black",
                        width: 2,
                        dash: "dot",
                      },
                    },
                  ],
                }}
                isLoading={isTimeSeriesLoading}
              />
            </CustomCard>
          </Group>
        </Stack>
        <Stack flex={1} h="100%">
          <CustomCard title="Timeline" style={{ height: "100%" }}>
            {eventsHistorical && (
              <DeviceEventsTimeline
                events={eventsHistorical}
                rootCauses={rootCauses || []}
                projectId={projectId || "-1"}
                selectedEvent={event || ({} as types.Event)}
              />
            )}
          </CustomCard>
        </Stack>
      </Group>
    </Stack>
  );
};

export default EventPage;

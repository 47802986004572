// Define an interface for the inner objects
interface Coordinates {
  latitude: number;
  longitude: number;
}

// Define the type for the outer object
type CountyCoordinates = {
  [county: string]: Coordinates;
};

export const countyCoordinates: CountyCoordinates = {
  Foard: { latitude: 33.97408519, longitude: -99.77871109 },
  Blanco: { latitude: 30.26636128, longitude: -98.39974086 },
  Hockley: { latitude: 33.60750375, longitude: -102.3430919 },
  Llano: { latitude: 30.70566579, longitude: -98.68387398 },
  Swisher: { latitude: 34.53064989, longitude: -101.734951 },
  Titus: { latitude: 33.2160906, longitude: -94.96509786 },
  Navarro: { latitude: 32.04695429, longitude: -96.47248134 },
  Brazoria: { latitude: 29.19345387, longitude: -95.45578479 },
  Hidalgo: { latitude: 26.396627, longitude: -98.18088681 },
  Archer: { latitude: 33.61539176, longitude: -98.68783437 },
  Refugio: { latitude: 28.32681413, longitude: -97.16546961 },
  Andrews: { latitude: 32.30506283, longitude: -102.6378839 },
  Galveston: { latitude: 29.39424415, longitude: -94.96474975 },
  "De Witt": { latitude: 29.08201599, longitude: -97.35666976 },
  Gonzales: { latitude: 29.45641495, longitude: -97.49279927 },
  Wharton: { latitude: 29.2778362, longitude: -96.22197375 },
  Fannin: { latitude: 33.59396919, longitude: -96.10656755 },
  Madison: { latitude: 30.96564645, longitude: -95.92818441 },
  Moore: { latitude: 35.83768863, longitude: -101.8929556 },
  Tyler: { latitude: 30.7712607, longitude: -94.37651432 },
  Lipscomb: { latitude: 36.27778432, longitude: -100.27317 },
  Burleson: { latitude: 30.49246139, longitude: -96.62109399 },
  "Jim Hogg": { latitude: 27.04340499, longitude: -98.6972921 },
  Throckmorton: { latitude: 33.17753907, longitude: -99.21236832 },
  Kleberg: { latitude: 27.43274395, longitude: -97.72889778 },
  Shelby: { latitude: 31.79242191, longitude: -94.14495261 },
  Garza: { latitude: 33.17991074, longitude: -101.2984496 },
  Karnes: { latitude: 28.90493782, longitude: -97.85960621 },
  Reeves: { latitude: 31.32307061, longitude: -103.693143 },
  Houston: { latitude: 31.31764137, longitude: -95.42225367 },
  "El Paso": { latitude: 31.7686226, longitude: -106.2352229 },
  Carson: { latitude: 35.40346802, longitude: -101.3542037 },
  Medina: { latitude: 29.35520493, longitude: -99.11008331 },
  Wichita: { latitude: 33.98421585, longitude: -98.70153389 },
  Chambers: { latitude: 29.73912276, longitude: -94.60891308 },
  Donley: { latitude: 34.96540246, longitude: -100.8140501 },
  Hopkins: { latitude: 33.14933822, longitude: -95.56414173 },
  Lampasas: { latitude: 31.19627791, longitude: -98.24142711 },
  Falls: { latitude: 31.25317017, longitude: -96.93579511 },
  Stephens: { latitude: 32.73584155, longitude: -98.83612016 },
  Travis: { latitude: 30.33423319, longitude: -97.78194654 },
  Roberts: { latitude: 35.8385577, longitude: -100.8135689 },
  Crosby: { latitude: 33.61459873, longitude: -101.2998612 },
  Kerr: { latitude: 30.06153035, longitude: -99.35016969 },
  Wood: { latitude: 32.78634374, longitude: -95.38206498 },
  Jack: { latitude: 33.23345819, longitude: -98.1725509 },
  Marion: { latitude: 32.79797929, longitude: -94.35725239 },
  Hale: { latitude: 34.07051782, longitude: -101.8269082 },
  "Jeff Davis": { latitude: 30.71559322, longitude: -104.1403539 },
  Tarrant: { latitude: 32.77185236, longitude: -97.29116473 },
  Waller: { latitude: 30.01093722, longitude: -95.98768942 },
  Reagan: { latitude: 31.36617698, longitude: -101.5230108 },
  Smith: { latitude: 32.37514553, longitude: -95.26909511 },
  "Van Zandt": { latitude: 32.56368961, longitude: -95.8364862 },
  Cooke: { latitude: 33.638465, longitude: -97.21247642 },
  Zavala: { latitude: 28.86530621, longitude: -99.76102001 },
  Hall: { latitude: 34.53074134, longitude: -100.6809876 },
  Kendall: { latitude: 29.9446397, longitude: -98.71160511 },
  Goliad: { latitude: 28.65708563, longitude: -97.42646082 },
  Yoakum: { latitude: 33.17293501, longitude: -102.8278792 },
  Knox: { latitude: 33.60614755, longitude: -99.74144313 },
  McLennan: { latitude: 31.55234489, longitude: -97.20184883 },
  Brewster: { latitude: 29.81205836, longitude: -103.2518906 },
  Panola: { latitude: 32.16222886, longitude: -94.30552858 },
  Bandera: { latitude: 29.74724753, longitude: -99.24637282 },
  Parmer: { latitude: 34.53014507, longitude: -102.784594 },
  Rockwall: { latitude: 32.89786769, longitude: -96.40780584 },
  Trinity: { latitude: 31.0885069, longitude: -95.13551682 },
  Bosque: { latitude: 31.90050476, longitude: -97.63437613 },
  Coke: { latitude: 31.88849293, longitude: -100.5298747 },
  Franklin: { latitude: 33.17550691, longitude: -95.21841437 },
  Hill: { latitude: 31.99079033, longitude: -97.1324142 },
  Calhoun: { latitude: 28.48966896, longitude: -96.64708119 },
  Irion: { latitude: 31.30393203, longitude: -100.9824126 },
  Midland: { latitude: 31.86921148, longitude: -102.0315032 },
  Brown: { latitude: 31.77432313, longitude: -98.99989649 },
  Wilson: { latitude: 29.17315896, longitude: -98.08621015 },
  Oldham: { latitude: 35.40497586, longitude: -102.6029949 },
  Freestone: { latitude: 31.70486443, longitude: -96.14918535 },
  Sabine: { latitude: 31.34321941, longitude: -93.85170439 },
  "Deaf Smith": { latitude: 34.96602014, longitude: -102.6048162 },
  Angelina: { latitude: 31.25475875, longitude: -94.611742 },
  Kent: { latitude: 33.18284492, longitude: -100.7791031 },
  "Red River": { latitude: 33.62074539, longitude: -95.0501886 },
  Kinney: { latitude: 29.35000302, longitude: -100.4178441 },
  Denton: { latitude: 33.205574, longitude: -97.11681154 },
  Sutton: { latitude: 30.49747152, longitude: -100.5382263 },
  Guadalupe: { latitude: 29.58257236, longitude: -97.94850308 },
  Winkler: { latitude: 31.85008609, longitude: -103.0484794 },
  Dimmit: { latitude: 28.42161168, longitude: -99.760574 },
  Wise: { latitude: 33.21585061, longitude: -97.65444124 },
  Hemphill: { latitude: 35.83750155, longitude: -100.2704628 },
  Potter: { latitude: 35.40128927, longitude: -101.8940484 },
  Childress: { latitude: 34.52929257, longitude: -100.2075664 },
  Willacy: { latitude: 26.47132899, longitude: -97.64303323 },
  Bastrop: { latitude: 30.10353518, longitude: -97.31187705 },
  Kimble: { latitude: 30.48676251, longitude: -99.74892716 },
  Scurry: { latitude: 32.74720063, longitude: -100.9165071 },
  Ochiltree: { latitude: 36.27846334, longitude: -100.815653 },
  Hansford: { latitude: 36.27753179, longitude: -101.3545624 },
  Hartley: { latitude: 35.83996755, longitude: -102.6028835 },
  Cameron: { latitude: 26.13847728, longitude: -97.51499968 },
  Matagorda: { latitude: 28.86711039, longitude: -95.98533866 },
  Burnet: { latitude: 30.78843382, longitude: -98.18252382 },
  Rains: { latitude: 32.87004212, longitude: -95.79347545 },
  Hays: { latitude: 30.05782657, longitude: -98.03104087 },
  Mason: { latitude: 30.71742293, longitude: -99.2263026 },
  Ward: { latitude: 31.509424, longitude: -103.1024636 },
  Delta: { latitude: 33.38623601, longitude: -95.67221685 },
  Nueces: { latitude: 27.72621549, longitude: -97.61767326 },
  Callahan: { latitude: 32.2977738, longitude: -99.37359692 },
  Bell: { latitude: 31.03778267, longitude: -97.47790776 },
  Cherokee: { latitude: 31.83695632, longitude: -95.16515234 },
  Ellis: { latitude: 32.34839264, longitude: -96.79467417 },
  Lubbock: { latitude: 33.6101944, longitude: -101.8205745 },
  Terrell: { latitude: 30.22502597, longitude: -102.0764533 },
  Crockett: { latitude: 30.72291931, longitude: -101.4118364 },
  Atascosa: { latitude: 28.89418432, longitude: -98.52741302 },
  Ector: { latitude: 31.86908156, longitude: -102.5430907 },
  Brazos: { latitude: 30.6610425, longitude: -96.3022943 },
  Aransas: { latitude: 28.12709562, longitude: -96.99258951 },
  Dallas: { latitude: 32.76653746, longitude: -96.77781862 },
  Howard: { latitude: 32.30610232, longitude: -101.4355097 },
  Motley: { latitude: 34.07409017, longitude: -100.7798961 },
  "San Augustine": { latitude: 31.39427005, longitude: -94.16809762 },
  Maverick: { latitude: 28.74260943, longitude: -100.3144638 },
  Erath: { latitude: 32.23640361, longitude: -98.21722756 },
  Dickens: { latitude: 33.61663428, longitude: -100.7788138 },
  Caldwell: { latitude: 29.83677935, longitude: -97.61983479 },
  Dawson: { latitude: 32.74258137, longitude: -101.9476136 },
  Camp: { latitude: 32.97321771, longitude: -94.97860185 },
  Duval: { latitude: 27.68133382, longitude: -98.50889672 },
  Hunt: { latitude: 33.123531, longitude: -96.08550014 },
  Mills: { latitude: 31.49524259, longitude: -98.59552736 },
  "Val Verde": { latitude: 29.89300587, longitude: -101.15171 },
  Pecos: { latitude: 30.78100044, longitude: -102.7235743 },
  Baylor: { latitude: 33.61648147, longitude: -99.21351964 },
  Gregg: { latitude: 32.48036452, longitude: -94.81723694 },
  Cochran: { latitude: 33.60410808, longitude: -102.8285264 },
  Jasper: { latitude: 30.74391255, longitude: -94.02495849 },
  Hamilton: { latitude: 31.70479181, longitude: -98.11066654 },
  Castro: { latitude: 34.53000304, longitude: -102.2617608 },
  Sterling: { latitude: 31.82786516, longitude: -101.0500699 },
  Starr: { latitude: 26.56212627, longitude: -98.73834895 },
  Crane: { latitude: 31.42846287, longitude: -102.5156425 },
  Morris: { latitude: 33.11368507, longitude: -94.73205137 },
  Briscoe: { latitude: 34.53041048, longitude: -101.2085769 },
  "Tom Green": { latitude: 31.4044442, longitude: -100.4620678 },
  Menard: { latitude: 30.88981785, longitude: -99.82061472 },
  Parker: { latitude: 32.77852261, longitude: -97.80472194 },
  Walker: { latitude: 30.73899492, longitude: -95.57224172 },
  Brooks: { latitude: 27.0315669, longitude: -98.21874127 },
  "La Salle": { latitude: 28.34621032, longitude: -99.1034773 },
  Edwards: { latitude: 29.98248216, longitude: -100.3048937 },
  Lee: { latitude: 30.31065065, longitude: -96.96568735 },
  Robertson: { latitude: 31.02705415, longitude: -96.51272568 },
  Young: { latitude: 33.17670764, longitude: -98.68781396 },
  Bee: { latitude: 28.41736675, longitude: -97.74116811 },
  Clay: { latitude: 33.78379717, longitude: -98.208958 },
  "San Jacinto": { latitude: 30.57948905, longitude: -95.16683273 },
  Uvalde: { latitude: 29.35669829, longitude: -99.76203107 },
  Haskell: { latitude: 33.17824805, longitude: -99.73029401 },
  Somervell: { latitude: 32.22229511, longitude: -97.77456553 },
  Bexar: { latitude: 29.44884523, longitude: -98.51966309 },
  Grayson: { latitude: 33.62684371, longitude: -96.67764864 },
  Bailey: { latitude: 34.0684915, longitude: -102.8299069 },
  Jones: { latitude: 32.73970429, longitude: -99.87850599 },
  Jefferson: { latitude: 29.88425832, longitude: -94.1708778 },
  Rusk: { latitude: 32.10803224, longitude: -94.76169577 },
  Glasscock: { latitude: 31.8694238, longitude: -101.520742 },
  Gillespie: { latitude: 30.31800246, longitude: -98.94627245 },
  Fisher: { latitude: 32.74305155, longitude: -100.4019503 },
  Henderson: { latitude: 32.21188078, longitude: -95.85356847 },
  Shackelford: { latitude: 32.7360276, longitude: -99.35403034 },
  "Palo Pinto": { latitude: 32.75316856, longitude: -98.31299522 },
  Wilbarger: { latitude: 34.08044953, longitude: -99.24089568 },
  Floyd: { latitude: 34.07239775, longitude: -101.3033037 },
  Orange: { latitude: 30.12807126, longitude: -93.89653794 },
  Runnels: { latitude: 31.83105393, longitude: -99.97624799 },
  Coleman: { latitude: 31.77329421, longitude: -99.45362579 },
  Martin: { latitude: 32.30605847, longitude: -101.9512464 },
  Wheeler: { latitude: 35.40129002, longitude: -100.2697634 },
  Culberson: { latitude: 31.44709817, longitude: -104.5177421 },
  Mitchell: { latitude: 32.30636024, longitude: -100.9211128 },
  Hudspeth: { latitude: 31.45613738, longitude: -105.3869428 },
  Upshur: { latitude: 32.7362827, longitude: -94.94132011 },
  Liberty: { latitude: 30.15153187, longitude: -94.8120925 },
  Williamson: { latitude: 30.64834941, longitude: -97.60105467 },
  Nacogdoches: { latitude: 31.61604108, longitude: -94.6158776 },
  Loving: { latitude: 31.84912973, longitude: -103.5799063 },
  "Jim Wells": { latitude: 27.73131099, longitude: -98.0898649 },
  Upton: { latitude: 31.36853957, longitude: -102.0430005 },
  Newton: { latitude: 30.78619977, longitude: -93.74462993 },
  Lamar: { latitude: 33.66746186, longitude: -95.57110784 },
  Polk: { latitude: 30.79250963, longitude: -94.82994949 },
  Collin: { latitude: 33.18789055, longitude: -96.57248871 },
  Hardeman: { latitude: 34.28865227, longitude: -99.7453851 },
  McMullen: { latitude: 28.35351091, longitude: -98.56842256 },
  Cottle: { latitude: 34.0776571, longitude: -100.2786983 },
  Frio: { latitude: 28.86726774, longitude: -99.10826004 },
  Gray: { latitude: 35.40130666, longitude: -100.812475 },
  Dallam: { latitude: 36.27777107, longitude: -102.6021647 },
  Kenedy: { latitude: 26.92409448, longitude: -97.68137783 },
  "Fort Bend": { latitude: 29.52763253, longitude: -95.77090562 },
  Armstrong: { latitude: 34.96522076, longitude: -101.3572412 },
  Collingsworth: { latitude: 34.96498493, longitude: -100.2700733 },
  Cass: { latitude: 33.07750446, longitude: -94.34348771 },
  Sherman: { latitude: 36.27765824, longitude: -101.8933822 },
  Terry: { latitude: 33.17373729, longitude: -102.335157 },
  Limestone: { latitude: 31.54541898, longitude: -96.58056718 },
  Real: { latitude: 29.83182711, longitude: -99.82215881 },
  Lynn: { latitude: 33.17685487, longitude: -101.8160235 },
  Lavaca: { latitude: 29.38437003, longitude: -96.93020993 },
  Nolan: { latitude: 32.3034718, longitude: -100.4060989 },
  Gaines: { latitude: 32.74074537, longitude: -102.6351246 },
  Montgomery: { latitude: 30.30022409, longitude: -95.50301411 },
  Concho: { latitude: 31.32645211, longitude: -99.86413426 },
  Hardin: { latitude: 30.33238431, longitude: -94.39021394 },
  Harris: { latitude: 29.85967144, longitude: -95.39782106 },
  Leon: { latitude: 31.29655719, longitude: -95.99554292 },
  Bowie: { latitude: 33.44572786, longitude: -94.42324298 },
  Kaufman: { latitude: 32.59927808, longitude: -96.28779341 },
  Anderson: { latitude: 31.81321543, longitude: -95.65251774 },
  "San Saba": { latitude: 31.15520543, longitude: -98.81754295 },
  Schleicher: { latitude: 30.89661056, longitude: -100.538097 },
  Colorado: { latitude: 29.6208542, longitude: -96.52630821 },
  Milam: { latitude: 30.78625299, longitude: -96.97683495 },
  "Live Oak": { latitude: 28.3514375, longitude: -98.12474493 },
  Borden: { latitude: 32.7436916, longitude: -101.4317533 },
  McCulloch: { latitude: 31.19896752, longitude: -99.34754972 },
  Johnson: { latitude: 32.37899918, longitude: -97.36660504 },
  Montague: { latitude: 33.67496328, longitude: -97.72466993 },
  Stonewall: { latitude: 33.17842158, longitude: -100.255259 },
  Comal: { latitude: 29.80816362, longitude: -98.27814516 },
  Eastland: { latitude: 32.32739258, longitude: -98.8325713 },
  Comanche: { latitude: 31.9490639, longitude: -98.55841538 },
  King: { latitude: 33.61641863, longitude: -100.2558584 },
  Washington: { latitude: 30.2145299, longitude: -96.40356575 },
  Webb: { latitude: 27.76102524, longitude: -99.33170164 },
  Fayette: { latitude: 29.87683207, longitude: -96.91968047 },
  Harrison: { latitude: 32.54814898, longitude: -94.37155736 },
  Presidio: { latitude: 29.99977786, longitude: -104.2405552 },
  Austin: { latitude: 29.88711223, longitude: -96.27791829 },
  Randall: { latitude: 34.96594288, longitude: -101.8968527 },
  Hood: { latitude: 32.43107878, longitude: -97.83280338 },
  Victoria: { latitude: 28.79640594, longitude: -96.97176602 },
  Hutchinson: { latitude: 35.84000866, longitude: -101.3547257 },
  Coryell: { latitude: 31.39087578, longitude: -97.7991883 },
  Jackson: { latitude: 28.95603133, longitude: -96.57883268 },
  Taylor: { latitude: 32.30138229, longitude: -99.89003941 },
  Zapata: { latitude: 27.00089072, longitude: -99.16860134 },
  "San Patricio": { latitude: 28.00922186, longitude: -97.51859857 },
  Grimes: { latitude: 30.54360689, longitude: -95.98557334 },
  Lamb: { latitude: 34.06861242, longitude: -102.3517095 },
};

import { PageLoader } from "@/components/Loading";
import { useGetProject } from "@/hooks/api";
import { Accordion, Group, Stack, Title } from "@mantine/core";
import { Link, useParams } from "react-router-dom";

const Page = () => {
  const { projectId } = useParams();
  const { data: project, isLoading: isProjectLoading } = useGetProject({
    pathParams: { projectId: projectId || "" },
  });
  if (isProjectLoading) return <PageLoader />;

  const reports = [
    ...(project?.has_pv_dc_combiners
      ? [
          {
            name: "DC Amperage Report",
            component: <DCAmperageReport />,
          },
        ]
      : []),
  ];

  return (
    <Stack p="md">
      <Title order={1}>Reports</Title>
      <Accordion variant="separated" chevronPosition="left">
        {reports.map((report, index) => (
          <Accordion.Item key={index} value={report.name}>
            <Accordion.Control>{report.name}</Accordion.Control>
            <Accordion.Panel>{report.component}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Stack>
  );
};

const DCAmperageReport = () => {
  const { projectId } = useParams();
  return (
    <Group>
      <Link
        to={`/projects/${projectId}/reports/dc-amperage`}
        style={{ color: "inherit" }}
      >
        DC Amperage Report
      </Link>
    </Group>
  );
};

export default Page;

import CustomCard from "@/components/CustomCard";
import { PageError } from "@/components/Error";
import { PageLoader } from "@/components/Loading";
import {
  useDeleteProjectDocument,
  useGetProjectDocuments,
  useUploadProjectDocument,
} from "@/hooks/api";
import {
  ActionIcon,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {
  Dropzone,
  FileRejection,
  FileWithPath,
  PDF_MIME_TYPE,
} from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

interface FileUploadProps {
  projectId: string;
}

export function FileUpload({
  projectId,
  uploadMutation,
}: FileUploadProps & {
  uploadMutation: ReturnType<typeof useUploadProjectDocument>;
}) {
  const MAX_MB = 15;

  const [opened, setOpened] = useState(false);
  const [file, setFile] = useState<FileWithPath | null>(null);
  // const uploadMutation = useUploadProjectDocument();

  const handleDrop = (files: FileWithPath[]) => {
    setFile(files[0]);
  };

  const handleReject = (fileRejections: FileRejection[]) => {
    fileRejections.forEach(({ file, errors }) => {
      if (errors[0]?.code === "file-too-large") {
        notifications.show({
          title: "File too large",
          message: `${file.name} exceeds the ${MAX_MB}MB limit`,
          color: "red",
        });
      } else {
        notifications.show({
          title: "Invalid file",
          message: `${file.name} is not a valid file type`,
          color: "red",
        });
      }
    });
  };

  const handleUpload = () => {
    if (file) {
      uploadMutation.mutate({ projectId, file });
      setOpened(false);
      setFile(null);
    }
  };

  return (
    <>
      <Button onClick={() => setOpened(true)}>Upload Document</Button>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          setFile(null);
        }}
        title="Upload Document"
      >
        <Stack>
          {file ? (
            <Text>File selected: {file.name}</Text>
          ) : (
            <Dropzone
              onDrop={handleDrop}
              onReject={handleReject}
              maxSize={MAX_MB * 1024 ** 2}
              accept={PDF_MIME_TYPE}
              multiple={false}
            >
              <Stack style={{ pointerEvents: "none" }}>
                <Text size="xl" inline>
                  Drag file here or click to select file
                </Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                  File should not exceed {MAX_MB}mb
                </Text>
              </Stack>
            </Dropzone>
          )}
          <Group w="100%">
            <Button
              flex={1}
              onClick={() => {
                setOpened(false);
                setFile(null);
              }}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              flex={1}
              onClick={handleUpload}
              loading={uploadMutation.isPending}
              disabled={!file}
            >
              Upload
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}

const ProjectDocuments = () => {
  const { projectId } = useParams();
  const documents = useGetProjectDocuments({
    pathParams: { projectId: projectId || "-1" },
  });

  const uploadMutation = useUploadProjectDocument();
  const deleteDocumentMutation = useDeleteProjectDocument();

  const handleDeleteDocument = (documentId: string) => {
    if (projectId) {
      deleteDocumentMutation.mutate({ projectId, documentId });
    }
  };

  if (documents.isLoading) return <PageLoader />;
  if (documents.error) return <PageError error={documents.error} />;
  if (uploadMutation.error) {
    notifications.show({
      id: "upload-error",
      title: "Upload Error",
      message: uploadMutation.error.response?.data.detail,
      color: "red",
    });
    uploadMutation.reset();
  }

  const rows = documents.data?.map((doc) => (
    <Table.Tr key={doc.document_id}>
      <Table.Td>
        <Link
          to={doc.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit" }}
        >
          {doc.name}
        </Link>
      </Table.Td>
      <Table.Td style={{ textAlign: "right" }}>
        <ActionIcon
          variant="subtle"
          color="red"
          onClick={() => handleDeleteDocument(doc.document_id)}
          aria-label="Delete document"
        >
          <IconTrash size={16} />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Stack h="100%" p="md">
      <Title>Project Documents</Title>
      {documents.data?.length === 0 ? (
        <Text>No documents uploaded for this project.</Text>
      ) : (
        <CustomCard title="Documents" fill>
          <ScrollArea h="100%">
            <LoadingOverlay
              visible={
                deleteDocumentMutation.isPending ||
                uploadMutation.isPending ||
                documents.isLoading ||
                documents.isFetching ||
                documents.isRefetching
              }
            />
            <Table striped highlightOnHover withRowBorders={false}>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </ScrollArea>
        </CustomCard>
      )}
      <Group>
        <FileUpload
          projectId={projectId || "-1"}
          uploadMutation={uploadMutation}
        />
      </Group>
    </Stack>
  );
};

export default ProjectDocuments;

import { useGetWaterfall } from "@/hooks/api";
import { Center, LoadingOverlay, Stack, Text } from "@mantine/core";
import { IconDatabaseOff } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PlotlyPlot from "./PlotlyPlot";

const LossWaterfall = ({ level }: { level: string }) => {
  const { projectId } = useParams();

  const [data, setData] = useState<number[]>([]);
  const [columns, setColumns] = useState<string[]>([]);

  const {
    data: waterfallDataString,
    isLoading,
    error,
  } = useGetWaterfall({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { level: level },
  });

  useEffect(() => {
    if (waterfallDataString) {
      // Parse the JSON string to an object
      const waterfallData = JSON.parse(waterfallDataString);
      // Extract data from the parsed object
      setColumns(waterfallData.columns);
      setData(waterfallData.data[0]);
    }
  }, [waterfallDataString]);
  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (error) {
    return (
      <Center h={"100%"} w={"100%"}>
        <Stack align="center">
          <IconDatabaseOff size={48} strokeWidth={1} />
          <Text>{error.response?.data.detail}</Text>
        </Stack>
      </Center>
    );
  }

  // Calculate yesterday's date
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedDate = yesterday.toISOString().split("T")[0];

  const measureArray = Array(columns.length).fill("relative");
  measureArray[columns.length - 1] = "total";

  return (
    <PlotlyPlot
      data={[
        {
          type: "waterfall",
          name: "Loss Waterfall",
          measure: measureArray,
          x: columns,
          y: data,
        } as Partial<Plotly.PlotData>,
      ]}
      layout={{
        title: formattedDate,
        yaxis: {
          title: "MWh",
        },
        margin: { t: 30 },
      }}
    />
  );
};

export default LossWaterfall;

import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Tree,
  TreeProps,
  TreeNodeData,
  useTree,
  RenderTreeNodePayload,
  Group,
  Checkbox,
  Text,
} from "@mantine/core";
import { IconChevronDown, IconTag } from "@tabler/icons-react";

interface VirtualTreeProps extends TreeProps {
  onTreeChange?: (tree: ReturnType<typeof useTree>) => void;
}

const VirtualTree = ({
  props,
  labelType,
}: {
  props: VirtualTreeProps;
  labelType: string;
}) => {
  const { data, onTreeChange, ...rest } = props;
  const tree = useTree();
  const prevTreeStateRef = useRef({ expandedState: {}, checkedState: {} });
  const [userClicked, setUserClicked] = useState<number>(0);
  const renderTreeNode = ({
    node,
    expanded,
    elementProps,
    tree,
    labelType,
  }: RenderTreeNodePayload & { labelType: string }) => {
    const checked = tree.isNodeChecked(node.value);
    const indeterminate = tree.isNodeIndeterminate(node.value);

    return (
      <Group gap="xs" {...elementProps}>
        <Checkbox.Indicator
          checked={checked}
          indeterminate={indeterminate}
          size="sm"
          onClick={() =>
            !checked ? tree.checkNode(node.value) : tree.uncheckNode(node.value)
          }
        />

        <Group
          gap={5}
          onClick={() => {
            tree.toggleExpanded(node.value);
            setUserClicked((prev) => prev + 1);
          }}
        >
          {node?.value?.toString().includes("device") && (
            <Group gap={5}>
              <Text size="14px">{node.label}</Text>
              <IconChevronDown
                size={14}
                style={{
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </Group>
          )}
          {!node?.value?.toString().includes("device") && (
            <Group gap={5}>
              <IconTag size={14} />
              {labelType === "device_name" && (
                <Text size="14px">{node.nodeProps?.device_name}</Text>
              )}
              {labelType === "scada_name" && (
                <Text size="14px">{node.nodeProps?.scada_name}</Text>
              )}
            </Group>
          )}
        </Group>
      </Group>
    );
  };

  const virtualizeData = useCallback(
    (nodes: TreeNodeData[]): TreeNodeData[] => {
      return nodes.map((node) => {
        const isExpanded = tree.expandedState[node.value];
        const isChecked = tree.isNodeChecked(node.value);
        const isIndeterminate = tree.isNodeIndeterminate(node.value);
        const shouldKeepChildren = isExpanded || isChecked || isIndeterminate;

        return {
          ...node,
          children: node.children
            ? shouldKeepChildren
              ? virtualizeData(node.children)
              : node.children.length
              ? []
              : undefined
            : undefined,
        };
      });
    },
    [data, tree.isNodeChecked, tree.isNodeIndeterminate]
  );

  const virtualizedData = useMemo(
    () => virtualizeData(data),
    [userClicked, data]
  );

  useEffect(() => {
    const currentTreeState = {
      expandedState: tree.expandedState,
      checkedState: tree.checkedState,
    };

    if (
      JSON.stringify(currentTreeState) !==
      JSON.stringify(prevTreeStateRef.current)
    ) {
      if (onTreeChange) {
        onTreeChange(tree);
      }
      prevTreeStateRef.current = currentTreeState;
    }
  }, [tree.expandedState, tree.checkedState, onTreeChange]);
  return (
    <Tree
      {...rest}
      data={virtualizedData}
      tree={tree}
      renderNode={(nodeProps: RenderTreeNodePayload) =>
        renderTreeNode({ ...nodeProps, labelType })
      }
    />
  );
};

export default React.memo(VirtualTree);
